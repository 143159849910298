import { useNavigate } from "react-router-dom";
import "./Style.css";
import { Button, Box, Typography, Stack, Dialog, DialogTitle } from "@mui/material";
import groupdiss from "./images/online_lesson.png";
import Navebar from "../../Navbar/Navebar";
import React from "react";
import "react-lazy-load-image-component/src/effects/blur.css";

import Colors from "../../utils/Colors";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
function Onboarding() {
  const { t, i18n } = useTranslation(["translationOnboarding"]);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSignInBtnClick = () => {
    navigate("/login");
  };

  useEffect(() => {
    // Clear localStorage
    localStorage.clear();

    // Re-set necessary data in localStorage
    const savedLanguage = i18n.language || "en";
    localStorage.setItem("i18nextLng", savedLanguage);
  });
  //   // Log the contents of localStorage for debugging
  //   for (let i = 0; i < localStorage.length; i++) {
  //     const key = localStorage.key(i);
  //     const value = localStorage.getItem(key);
  //     console.log(`Key: ${key}, Value: ${value}`);
  //   }
  // }, [i18n.language]);
  return (
    <div>
      <Navebar title={t("Onboarding")} />
      <Box className='twoheading'>
        <Stack textAlign={"center"}>
          <Typography sx={{ fontWeight: 600, fontSize: "28px" }}>
            {t("Welcome To GillGall")}
          </Typography>
          <Typography sx={{ fontWeight: 400, fontSize: "18px", opacity: "0.5" }}>
            {t("Harvest The Knowledge")}
          </Typography>
        </Stack>
        <Box>
          <img alt='groupdiss' src={groupdiss} className='groupdiss' />
        </Box>
        <Stack textAlign={"center"} gap={2} mt={3}>
          <Button
            onClick={() => {
              navigate("/selectboard");
            }}
            variant='contained'
            sx={{
              backgroundColor: Colors.globalColorThemeColor,
              "&:hover": {
                backgroundColor: Colors.globalColorThemeColor, // Background color on hover
              },
            }}
          >
            {t("CREATE NEW ACCOUNT")}
          </Button>
          <Typography>{t("Already Have An Account ?")}</Typography>
          <Button onClick={onSignInBtnClick} variant='outlined'>
            {t("SIGN IN")}
          </Button>

          <p className='termsandservice'>
            {t("By creating an account,you are agreeing to our")}{" "}
            <span
              className='terms'
              style={{ "--bgcolor": Colors.globalColorThemeColor }}
              onClick={handleClickOpen}
            >
              {t("Terms of Services")}
            </span>{" "}
          </p>
        </Stack>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          className='termsandcondition'
          style={{ "--bgcolor": Colors.globalColorThemeColor, position: "sticky", top: "0px" }}
        >
          {t("Terms&Condition")}
        </DialogTitle>
        <div className='termscondition' style={{ overflowY: "scroll", overflowX: "hidden" }}>
          <div>
            <h3>{t("Privacy Policy")}</h3>
            <p>{t("Privacy Policy Content")}</p>
            <h3>{t("User Provided Information")}</h3>
            <p>{t("User Provided Information Content")}</p>
            <h3>{t("Automatically Collected Information")}</h3>
            <p>{t("Automatically Collected Information Content")}</p>
            <h3>{t("Use Of Your Personal Information")}</h3>
            <p>{t("Use Of Your Personal Information Content")}</p>
          </div>
        </div>
        <Stack alignItems={"center"} mt={5} mb={2}>
          <Button
            onClick={handleClose}
            variant='contained'
            sx={{
              textTransform: "capitalize",
              bgcolor: Colors.globalColorThemeColor,
              ":hover": { bgcolor: Colors.globalColorThemeColor },
              width: "150px",
            }}
          >
            {t("Close")}
          </Button>
        </Stack>
      </Dialog>
    </div>
  );
}

export default Onboarding;
