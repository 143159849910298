import React, { useEffect } from "react";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import { Box, Stack, Typography } from "@mui/material";
import GillgallLogo from "./images/gillgallLogo.png";
import PlayttoreLogo from "./images/playstoreLogo.svg";
import Colors from "../../utils/Colors";
import { useNavigate } from "react-router-dom";
import { tokenVerify } from "../../utils/Helpers";
import { useTranslation } from "react-i18next";
function AboutUs() {
  const { t } = useTranslation(["translationAboutUs"]);
  const navigate = useNavigate();

  const aboutContent = t("aboutGillgall");

  useEffect(() => {
    const isValidToken = tokenVerify();
    if (!isValidToken) {
      navigate("/home");
    }
  }, [navigate]);
  const token = localStorage.getItem("token");

  return (
    <>
      <Box>
        <SubjectNavbar title={"About US"} />
        <Stack alignItems={"center"} justifyContent={"center"} sx={{ mt: 6 }}>
          <Box sx={{ width: { xs: "90%", md: "90%", lg: "60%" } }}>
            <Stack alignItems={"center"}>
              <Box component={"img"} src={GillgallLogo} sx={{ width: "98px", height: "98px" }} />
              <Typography
                variant='h2'
                sx={{
                  mt: 2,
                  fontWeight: "600px",

                  color: Colors.globalColorThemeColor,
                }}
              >
                {t("GillGall")}
              </Typography>
              <Typography
                sx={{
                  mt: 2,
                  color: Colors.globalColorThemeColor,
                  fontWeight: "700px",
                  fontSize: { xs: "25px", md: "40px" },
                }}
              >
                {t("Harvest The Knowledge")}
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "20px", sm: "25px", md: "30px" },
                  mt: 2,
                  textAlign: "justify",
                  fontStyle: "normal",
                }}
              >
                {aboutContent}
              </Typography>
              <Stack alignItems={"center"} mt={4}>
                <Typography variant='h5' sx={{ fontWeight: "bold" }}>
                  {t("Try Our Mobile App")}
                </Typography>
                <Box
                  component={"img"}
                  src={PlayttoreLogo}
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.netcom.netsmartapp",
                    )
                  }
                  sx={{
                    cursor: "pointer",
                    mt: 2,
                    mb: { xs: 2, md: 0 },
                    width: { xs: "85%", md: "100%" },
                  }}
                />
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
}

export default AboutUs;
