import React, { useState, useRef, useEffect } from "react";
import "../unitstyle.css";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Card from "@mui/material/Card";
import Button from "../../../common/components/DesignSystem/Button/Button";
import ReactQuill from "react-quill";
import {
  CurrentBookmarkUnitTopics,
  CurrentUnitTopics,
  CurrentAllClassBookmarksData,
  setAllClassBookmarksData,
} from "../../../redux/unitTopicsSlice";
import { useDispatch, useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import "react-html5video/dist/styles.css";
import NoVideoImg from "../Image/noVideo.svg";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { CurrentBoard } from "../../../redux/registerDataSlice";
import axios from "axios";
import { APIClient } from "../../../utils/APIClient";
import Colors from "../../../utils/Colors";
import { useTranslation } from "react-i18next";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
const UnitVideos = (props) => {
  const videoList = props?.videoList || [];
  const unitId = props.unitList.map((unit) => unit.unitId);
  const currentBoards = useSelector(CurrentBoard);
  const { t } = useTranslation(["translationUnitsubvideos"]);
  const [selectedVideo, setSelectedVideo] = useState(videoList[0]);
  const [auto, setAuto] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mob_no, tn_medium, className } = props.loginData;
  const bookmarkMedium =
    tn_medium === "tamil_medium" ? "tamMedBookmarksData" : "engMedBookmarksData";
  const unitTopicsData = useSelector(CurrentUnitTopics);
  const bookmarkUnitTopics = useSelector(CurrentBookmarkUnitTopics);
  const AllClassBookmarkData = useSelector(CurrentAllClassBookmarksData);
  const [selection, setSelection] = useState(0);
  const demoVideoURL =
    "https://gillgall-app-public.s3.ap-south-1.amazonaws.com/appDemoVideo/Gillgall+30+SEC+OUT.mp4";
  const [videoPlayingURL, setVideoPlayingURL] = useState(demoVideoURL);
  const [videoPlayingTitle, setVideoPlayingTitle] = useState("");
  const mergedArray =
    unitTopicsData !== undefined
      ? unitTopicsData.map((obj1, index) => {
          if (Object.keys(AllClassBookmarkData).length !== 0) {
            if (Object.keys(AllClassBookmarkData).includes(bookmarkMedium)) {
              const checkKey = Object.keys(AllClassBookmarkData[bookmarkMedium]).includes(
                className,
              );
              if (checkKey) {
                const obj2 = AllClassBookmarkData[bookmarkMedium][className][props.subjectname]
                  ? AllClassBookmarkData[bookmarkMedium][className][props.subjectname].find(
                      (obj2) =>
                        obj2.unitSelectedIndex === props.unitSelectedIndex &&
                        obj2.unitTopicIndex === index,
                    )
                  : [].find(
                      (obj2) =>
                        obj2.unitSelectedIndex === props.unitSelectedIndex &&
                        obj2.unitTopicIndex === index,
                    );
                return { ...obj1, ...obj2 };
              } else {
                const obj2 = [].find(
                  (obj2) =>
                    obj2.unitSelectedIndex === props.unitSelectedIndex &&
                    obj2.unitTopicIndex === index,
                );
                return { ...obj1, ...obj2 };
              }
            } else {
              const obj2 = [].find(
                (obj2) =>
                  obj2.unitSelectedIndex === props.unitSelectedIndex &&
                  obj2.unitTopicIndex === index,
              );
              return { ...obj1, ...obj2 };
            }
          } else {
            const obj2 = [].find(
              (obj2) =>
                obj2.unitSelectedIndex === props.unitSelectedIndex && obj2.unitTopicIndex === index,
            );
            return { ...obj1, ...obj2 };
          }
        })
      : {};

  const [showMore, setShowMore] = useState(false);

  function handleViewMore() {
    setShowMore(true);
  }

  function handleViewLess() {
    setShowMore(false);
  }

  const bookmarkAddFn = (value) => {
    try {
      let bookmarkData = {
        unitTopicName: value.topicName,
        unitSelectedIndex: props.unitSelectedIndex,
        unitTopicIndex: selection,
      };
      let localBookmarkData = AllClassBookmarkData;
      let localData;
      if (Object.keys(localBookmarkData).length) {
        if (AllClassBookmarkData[bookmarkMedium]) {
          const localBookmarkClassDataKeys = Object.keys(AllClassBookmarkData[bookmarkMedium]);
          if (localBookmarkClassDataKeys.includes(className)) {
            const localBookmarkDataKeys = Object.keys(
              AllClassBookmarkData[bookmarkMedium][className],
            );
            let checkingVal = localBookmarkDataKeys.includes(props.subjectname)
              ? [...localBookmarkData[bookmarkMedium][className][props.subjectname], bookmarkData]
              : [bookmarkData];
            localData = {
              ...localBookmarkData,
              [bookmarkMedium]: {
                ...localBookmarkData[bookmarkMedium],
                [className]: {
                  ...localBookmarkData[bookmarkMedium][className],
                  [props.subjectname]: checkingVal,
                },
              },
            };
          } else {
            localData = {
              ...localBookmarkData,
              [bookmarkMedium]: {
                ...localBookmarkData[bookmarkMedium],
                [className]: {
                  [props.subjectname]: [bookmarkData],
                },
              },
            };
          }
        } else {
          localData = {
            ...localBookmarkData,
            [bookmarkMedium]: {
              [className]: {
                [props.subjectname]: [bookmarkData],
              },
            },
          };
        }
      } else {
        localData = {
          mob_no: mob_no,
          [bookmarkMedium]: {
            [className]: {
              [props.subjectname]: [bookmarkData],
            },
          },
        };
      }

      dispatch(setAllClassBookmarksData(localData));

      axios.post(`${APIClient().API_BASE_URL}/api/updatebookmarksdata`, localData);
    } catch (error) {
      console.info(error);
    }
  };

  const bookmarkRemoveFn = (value) => {
    let localBookmarkData = AllClassBookmarkData;
    const removedData = localBookmarkData[bookmarkMedium][className][props.subjectname].filter(
      (e) => !(e.unitSelectedIndex === props.unitSelectedIndex && e.unitTopicIndex === selection),
    );

    let RemovedlocalData = {
      ...localBookmarkData,
      [bookmarkMedium]: {
        ...localBookmarkData[bookmarkMedium],
        [className]: {
          ...localBookmarkData[bookmarkMedium][className],
          [props.subjectname]: removedData,
        },
      },
    };

    let temp = RemovedlocalData[bookmarkMedium][className];

    const filteredObj = Object.fromEntries(
      Object.entries(temp).filter(([key, value]) => Array.isArray(value) && value.length > 0),
    );

    RemovedlocalData[bookmarkMedium][className] = filteredObj;

    if (Object.keys(RemovedlocalData[bookmarkMedium][className]).length === 0) {
      delete RemovedlocalData[bookmarkMedium][className];
      if (Object.keys(RemovedlocalData[bookmarkMedium]).length === 0) {
        delete RemovedlocalData[bookmarkMedium];
        if (Object.keys(RemovedlocalData).length > 1) {
          dispatch(setAllClassBookmarksData(RemovedlocalData));
          axios.post(`${APIClient().API_BASE_URL}/api/updatebookmarksdata`, RemovedlocalData);
        } else {
          dispatch(setAllClassBookmarksData({}));
          axios.post(`${APIClient().API_BASE_URL}/api/updatebookmarksdata`, RemovedlocalData);
        }
      } else {
        dispatch(setAllClassBookmarksData(RemovedlocalData));
        axios.post(`${APIClient().API_BASE_URL}/api/updatebookmarksdata`, RemovedlocalData);
      }
    } else {
      dispatch(setAllClassBookmarksData(RemovedlocalData));
      axios.post(`${APIClient().API_BASE_URL}/api/updatebookmarksdata`, RemovedlocalData);
    }
  };

  useEffect(() => {
    const dataToSent = { mob_no };
    axios.post(`${APIClient().API_BASE_URL}/api/bookmarksdata`, dataToSent).then((res) => {
      if (res.data.responseData !== "") {
        dispatch(setAllClassBookmarksData(res.data.responseData[0]));
      } else {
        dispatch(setAllClassBookmarksData({}));
      }
    });
    setSelection(bookmarkUnitTopics.unitTopicIndex ? bookmarkUnitTopics.unitTopicIndex : 0);
  }, [unitTopicsData]);

  const scrollRef = useRef(null);

  const handleScrollToTop = (index) => {
    if (!unitTopicsData[index].isLocked) {
      setSelection(index);
      const element = document.getElementById("videopart");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleContextMenu = (event) => {
    event.preventDefault(); //Prevent the default right-click behavior
  };
  const [sideVideoList, setSideVideoList] = useState([]);
  // let sideVideoList = [];
  // videoList.filter((item) => item.topicName !== selectedVideo.topicName) || [];
  useEffect(() => {
    if (videoList) {
      setSelectedVideo(videoList[0]);
      // let sideVideos = videoList.filter((item) => item.topicName !== videoList[0].topicName);
      // setSideVideoList(sideVideos);
      // console.log(sideVideoList);
    }
  }, [videoList]);
  const handleUnlock = () => {
    navigate("/stateboardsubscription");
  };
  const play = () => {
    const payload = {
      unitId: unitId,
      board: currentBoards,
    };
    console.log(payload, "pay");
    axios.post(`${APIClient().API_BASE_URL}/api/autoGeneratedUrl`, payload).then((res) => {
      setAuto(res.data.data);
    });
  };
  useEffect(() => {
    if (selectedVideo) {
      let sideVideos = videoList.filter((item) => item.topicName !== selectedVideo.topicName);
      setSideVideoList(sideVideos);
    }
  }, [selectedVideo]);
  useEffect(() => {
    if (selectedVideo) {
      play();
    }
  }, [selectedVideo]);
  return (
    <>
      <div ref={scrollRef}>
        <div id='videopart' ref={scrollRef}>
          {videoList.length === 0 ? (
            <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "50vh" }}>
              <Typography variant='h6' sx={{ fontWeight: "100px" }}>
                {t("No Data Found")}
              </Typography>
            </Stack>
          ) : selectedVideo ? (
            <div className='scrollPart'>
              <Box className='unitVideosMainDiv'>
                <Box className='mainVideopartDiv'>
                  <div className='me'>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: { xs: "15px", sm: "18px", md: "20px", lg: "20px", xl: "20px" },
                        color: props.subjectColor,
                      }}
                    >
                      {selectedVideo?.topicName || ""}
                    </Typography>
                    {selectedVideo && (
                      <MediaPlayer
                        className='mediaplayer'
                        title={selectedVideo?.topicName}
                        src={selectedVideo?.topicVideoURL}
                      >
                        <MediaProvider />
                        <DefaultVideoLayout
                          thumbnails='https://files.vidstack.io/sprite-fight/thumbnails.vtt'
                          icons={defaultLayoutIcons}
                        />
                      </MediaPlayer>
                    )}

                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "600",
                          fontSize: { xs: "15px", sm: "18px", md: "20px", lg: "20px", xl: "20px" },
                          color: props.subjectColor,
                        }}
                      >
                        {t("Description")}
                      </Typography>
                      <ReactQuill
                        value={selectedVideo.topicDescription}
                        readOnly={true}
                        modules={{ toolbar: false }}
                        style={{ border: "none" }}
                      />
                    </Box>
                  </div>
                </Box>

                <Box className='sideVideoPar'>
                  {sideVideoList.map((e, index) => (
                    <Box
                      key={index}
                      sx={{
                        border: `2px solid ${props.subjectColor}`,
                        borderRadius: "10px",
                        overflow: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                        justifyContent: "space-between",
                        marginBottom: "25px",
                      }}
                      className='unitSideVidepar'
                      onClick={() => {
                        if (e.isLocked) return;
                        // setVideoPlayingTitle(e.topicName);
                        setSelectedVideo(e);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      <div style={{ position: "relative", width: "100%", height: "180px" }}>
                        {e.topicThumbnailURL === "" ? (
                          <Typography
                            sx={{
                              justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                              background: props.subjectColor,
                              opacity: "0.8",
                              height: "100%",
                              fontWeight: "600",
                            }}
                          >
                            Coming Soon...
                          </Typography>
                        ) : (
                          <img
                            src={
                              // "https://i.postimg.cc/7hzfr659/asian-teacher-teaching-online-having-remote-class-standing-workplace-116547-25830.avif"
                              e.topicThumbnailURL
                            }
                            alt='No Video'
                            style={{ width: "100%", height: "100%", objectFit: "fill" }}
                          />
                        )}
                        {e.isLocked ? (
                          <LockOutlinedIcon
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              fontSize: "50px",
                              color: "#DAA520",
                            }}
                          />
                        ) : (
                          <PlayCircleOutlineIcon
                            sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              fontSize: "50px",
                              color: props.subjectColor,
                            }}
                          />
                        )}
                      </div>
                      <div
                        style={{
                          padding: "10px 20px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ fontWeight: "600", height: "50px" }}>
                          {e.topicName}
                        </Typography>
                        <Button
                          variant='contained'
                          customStyles={{
                            width: "100%",
                            marginTop: "10px",
                            backgroundColor: e.isLocked ? "#DAA520" : props.subjectColor,
                          }}
                          onClick={e.isLocked === true ? handleUnlock : play}
                        >
                          {e.isLocked === true ? t("Unlock") : t("Play")}
                        </Button>
                      </div>
                    </Box>
                  ))}
                </Box>
              </Box>
            </div>
          ) : (
            <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "50vh" }}>
              <Typography variant='h6' sx={{ fontWeight: "100px" }}>
                No Data Found
              </Typography>
            </Stack>
          )}
        </div>
      </div>
    </>
  );
};
export default UnitVideos;
