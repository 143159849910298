import {
  Card,
  // Container,
  Button,
  Typography,
  Chip,
} from "@mui/material";
import React from "react";
import "./NEETHOMEPAGE.css";
import Physics from "../../assets/physics.png";
import Circle from "../../assets/circle.png";
import Botany from "../../assets/Botany.png";
import Zoology from "../../assets/zoology.png";
import Chemistry from "../../assets/chemistry.png";
import GillGall from "../../assets/Gillgall.png";
// import Appbar from './Appbar'
import Fullimg from "../../assets/fullimg.png";
const Main = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          backgroundColor: "#35affd",
          alignItems: "center",
          height: "100px",
          overflow: "hidden",
          position: "sticky",
          top: "0px",
          zIndex: "1",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={GillGall} alt='Gillgall' style={{ height: "70px", width: "70px" }} />
        </div>
        <div>
          <Typography
            sx={{ fontSize: "28px", color: "white", fontWeight: "600", textAlign: "center" }}
          >
            GillGall
          </Typography>
          <button
            style={{
              borderRadius: "30px",
              fontSize: "18px",
              width: "180px",
              height: "35px",
              color: "#35affd",
              border: "white",
              fontWeight: "600",
              marginTop: "5px",
            }}
          >
            Neet
          </button>
        </div>
        <div style={{ width: "100px", height: "50px" }}>
          <Chip
            style={{
              backgroundColor: "white",
              height: "40px",
              width: "40px",
              borderRadius: "25px",
              color: "#35affd",
              fontWeight: "600",
              fontSize: "18px",
            }}
            label='A'
            variant='outlined'
          />
          <Typography style={{ color: "white" }}>Amy</Typography>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
        <img src={Fullimg} alt='Fullimg' style={{ height: "477px", width: "1312px" }} />
      </div>
      <Typography className='neet'>NEET</Typography>
      <Typography className='live'>Live Online Classes</Typography>
      <li className='listed'>Save Time</li>
      <li className='listed'>Learn at your own </li>
      <div style={{ marginBottom: "30px", paddingLeft: "20px" }}>
        <div style={{ marginLeft: "270px" }}>
          <Button
            variant='outlined'
            style={{
              borderColor: "#35affd",
              width: "15vh",
              display: "flex",
              justifyContent: "center",
              color: "#35affd",
              marginTop: "-60px",
              textAlign: "center",
            }}
          >
            Subject
          </Button>
        </div>
        <div style={{ alignItems: "center" }}>
          <Card className='card' elevation={12}>
            <div className='whole'>
              <div className='logo'>
                <img src={Physics} alt='physics' className='phy' />
              </div>
              <h3 className='sub'>Physics</h3>
              <div className='circle'>
                <img src={Circle} alt='Circle' className='phy' />
              </div>
            </div>
          </Card>
          <Card className='card' elevation={12}>
            <div className='whole'>
              <div className='logo'>
                <img src={Botany} alt='Botany' className='phy' />
              </div>
              <h3 className='sub'>Botany</h3>
              <div className='circle'>
                <img src={Circle} alt='Circle' className='phy' />
              </div>
            </div>
          </Card>
          <Card className='card' elevation={12}>
            <div className='whole'>
              <div className='logo'>
                <img src={Zoology} alt='Zoology' className='phy' />
              </div>
              <h3 className='sub'>Zoology</h3>
              <div className='circle'>
                <img src={Circle} alt='Circle' className='phy' />
              </div>
            </div>
          </Card>
          <Card className='card' elevation={12}>
            <div className='whole'>
              <div className='logo'>
                <img src={Chemistry} alt='Chemistry' className='phy' />
              </div>
              <h3 className='sub'>Chemistry</h3>
              <div className='circle'>
                <img src={Circle} alt='Circle' className='phy' />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default Main;
