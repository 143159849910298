/* eslint-disable */
import React from "react";
import { DialogTitle, Dialog, Button, Stack } from "@mui/material";
import "./TermsandconditionStyle.css";
import Colors from "../../utils/Colors";
import { useTranslation } from "react-i18next";
function Termsandcondition(props) {
  const {t}=useTranslation(["translationTermsandcondition"]);
  return (
    <Dialog open={props.open} onClose={props.close} >
      <DialogTitle
        className='termsandcondition'
        style={{ "--bgcolor": Colors.globalColorThemeColor, position: "sticky", top: "0px" }}
      >
        {t("Terms&Condition")}
      </DialogTitle>
      <div className='termscondition' style={{ overflowY: "scroll", overflowX: "hidden" }}>
        <div>
          <h3>{t("Privacy Policy")}</h3>
           <p>{t("Privacy Policy Content")}</p>
          <h3>{t("User Provided Information")}</h3>
          <p>{t("User Provided Information Content")}</p>
          <h3>{t("Automatically Collected Information")}</h3>
          <p>{t("Automatically Collected Information Content")}</p>
          <h3>{t("Use Of Your Personal Information")}</h3>
          <p>{t("Use Of Your Personal Information Content")}</p>
        </div>
      </div>
      <Stack alignItems={"center"} mt={5} mb={2}>
        <Button
          onClick={props.close}
          variant='contained'
          sx={{
            textTransform: "capitalize",
            bgcolor: Colors.globalColorThemeColor,
            ":hover": { bgcolor: Colors.globalColorThemeColor },
            width: "150px",
          }}
        >
          {t("Close")}
        </Button>
      </Stack>
    </Dialog>
  );
}

export default Termsandcondition;
