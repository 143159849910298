export const itemListEM = [
  {
    title: "Tamil",
    img: "https://i.postimg.cc/j2YDkQdh/tamIcon.png",
    color: "#287F87",
  },
  {
    title: "English",
    img: "https://i.postimg.cc/N0GSmW66/engIcon.png",
    color: "#04019A",
  },
  {
    title: "Maths",
    img: "https://i.postimg.cc/pT3nDMFy/matIcon.png",
    color: "#39A2DB",
  },
  {
    title: "Environmental Science",
    img: "https://i.postimg.cc/WbgBQwzF/evsIcon.png",
    color: "#31BC00",
  },
  {
    title: "Environmental Studies (EVS)",
    img: "https://i.postimg.cc/WbgBQwzF/evsIcon.png",
    color: "#31BC00",
  },
  {
    title: "Science",
    img: "https://i.postimg.cc/43DdW3qz/sciIcon.png",
    color: "#FFB341",
  },
  {
    title: "Social Science",
    img: "https://i.postimg.cc/BvcBxtmV/socIcon.png",
    color: "#CD7F32",
  },
  {
    title: "History",
    img: "https://i.postimg.cc/QtC2Kg0m/hisIcon.png",
    color: "#E0701A",
  },
  {
    title: "Geography",
    img: "https://i.postimg.cc/1XN7HS7d/geoIcon.png",
    color: "#39A6A3",
  },
  {
    title: "Civics",
    img: "https://i.postimg.cc/mD2K0vq8/civIcon.png",
    color: "#2C2C2E",
  },
  {
    title: "Economics",
    img: "https://i.postimg.cc/JnYrHzmw/ecoIcon.png",
    color: "#990134",
  },
  {
    title: "Physics",
    img: "https://i.postimg.cc/CxztMWgd/phyIcon.png",
    color: "#D83955",
  },
  {
    title: "Chemistry",
    img: "https://i.postimg.cc/HkcsfY6L/cheIcon.png",
    color: "#00CC99",
  },
  {
    title: "Botany",
    img: "https://i.postimg.cc/NFy9j6Q3/botIcon.png",
    color: "#5D8234",
  },
  {
    title: "Zoology",
    img: "https://i.postimg.cc/qv8TdNHj/zooIcon.png",
    color: "#753422",
  },
  {
    title: "Accountancy",
    img: "https://i.postimg.cc/HxptShTT/accIcon.png",
    color: "#CE2C21",
  },
  {
    title: "Commerce",
    img: "https://i.postimg.cc/dt02Xxg0/comIcon.png",
    color: "#9B72AA",
  },
  {
    title: "Statistics",
    img: "https://i.postimg.cc/JnWg5L60/staIcon.png",
    color: "#666698",
  },
  {
    title: "Computer Science",
    img: "https://i.postimg.cc/7LJK7BSK/comp.png",
    color: "#726F82",
  },
  {
    title: "Informatics Practices",
    img: "https://i.postimg.cc/MKXbrRsd/rjjack.png",
    color: "#726F82",
  },
];

export const itemListTM = [
  {
    title: "தமிழ்",
    img: "https://i.postimg.cc/j2YDkQdh/tamIcon.png",
    color: "#287F87",
  },
  {
    title: "ஆங்கிலம்",
    img: "https://i.postimg.cc/N0GSmW66/engIcon.png",
    color: "#04019A",
  },
  {
    title: "கணிதம்",
    img: "https://i.postimg.cc/pT3nDMFy/matIcon.png",
    color: "#39A2DB",
  },
  {
    title: "கணிதவியல்",
    img: "https://i.postimg.cc/pT3nDMFy/matIcon.png",
    color: "#287F87",
  },

  {
    title: "கணக்கு",
    img: "https://i.postimg.cc/pT3nDMFy/matIcon.png",
    color: "#287F87",
  },

  {
    title: "சூழ்நிலையியல்‌",
    img: "https://i.postimg.cc/WbgBQwzF/evsIcon.png",
    color: "#31BC00",
  },
  {
    title: "அறிவியல்",
    img: "https://i.postimg.cc/43DdW3qz/sciIcon.png",
    color: "#FFB341",
  },
  {
    title: "சமூக அறிவியல்",
    img: "https://i.postimg.cc/BvcBxtmV/socIcon.png",
    color: "#CD7F32",
  },
  {
    title: "வரலாறு",
    img: "https://i.postimg.cc/QtC2Kg0m/hisIcon.png",
    color: "#E0701A",
  },
  {
    title: "புவியியல்",
    img: "https://i.postimg.cc/1XN7HS7d/geoIcon.png",
    color: "#39A6A3",
  },
  {
    title: "குடிமையியல்",
    img: "https://i.postimg.cc/mD2K0vq8/civIcon.png",
    color: "#2C2C2E",
  },
  {
    title: "பொருளியல்",
    img: "https://i.postimg.cc/JnYrHzmw/ecoIcon.png",
    color: "#990134",
  },
  {
    title: "பொருளாதாரம்",
    img: "https://i.postimg.cc/JnYrHzmw/ecoIcon.png",
    color: "#990134",
  },
  {
    title: "இயற்பியல்",
    img: "https://i.postimg.cc/CxztMWgd/phyIcon.png",
    color: "#D83955",
  },
  {
    title: "வேதியியல்",
    img: "https://i.postimg.cc/HkcsfY6L/cheIcon.png",
    color: "#00CC99",
  },
  {
    title: "தாவரவியல்",
    img: "https://i.postimg.cc/NFy9j6Q3/botIcon.png",
    color: "#5D8234",
  },
  {
    title: "விலங்கியல்",
    img: "https://i.postimg.cc/qv8TdNHj/zooIcon.png",
    color: "#753422",
  },
  {
    title: "கணக்கு பதிவியல்",
    img: "https://i.postimg.cc/HxptShTT/accIcon.png",
    color: "#CE2C21",
  },
  {
    title: "வணிகவியல்",
    img: "https://i.postimg.cc/dt02Xxg0/comIcon.png",
    color: "#9B72AA",
  },
  {
    title: "வணிகம்",
    img: "https://i.postimg.cc/dt02Xxg0/comIcon.png",
    color: "#9B72AA",
  },
  {
    title: "புள்ளியியல்",
    img: "https://i.postimg.cc/JnWg5L60/staIcon.png",
    color: "#666698",
  },
  {
    title: "கணினி அறிவியல்",
    img: "https://i.postimg.cc/7LJK7BSK/comp.png",
    color: "#726F82",
  },
];
