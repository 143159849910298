import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from "@mui/material";
import Star from "../../assets/ExamResultImages/star.png";
import { useLocation, useNavigate } from "react-router-dom";
import PercentageCircle from "./PercentageCircle";
import html2pdf from "html2pdf.js";
import ReactQuill from "react-quill";
import "./ExamResult.css";
import { useTranslation } from "react-i18next";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
const ExamResult = () => {
  const { t } = useTranslation(["translationExamResult"]);
  const navigate = useNavigate();
  const location = useLocation();
  const wrongAnswer = location.state.wrongAnswer;
  const correctAnswer = location.state.correctAnsCount;
  const userAnswers = location.state.userAnswers || [];
  const questionName = location.state.questionType || "";
  const whichyear = location.state.year || "";
  const marksGained = location.state.marksGained;
  const highestMarks = location.state.highestMarks;
  const totalQuestions = correctAnswer + wrongAnswer;
  const correctPercentage = (correctAnswer / totalQuestions) * 100;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toHundred = (marksGained / totalQuestions) * 100;
  const score = (toHundred / 100) * 5;
  const calculateStars = (score) => {
    if (score >= 0.6 && score <= 1) return 1;
    if (score >= 1.1 && score <= 1.5) return 1;
    if (score >= 1.6 && score <= 1.9) return 1.5;
    if (score >= 2 && score <= 2.4) return 2;
    if (score >= 2.5 && score <= 2.9) return 2.5;
    if (score >= 3 && score <= 3.4) return 3;
    if (score >= 3.5 && score <= 3.9) return 3.5;
    if (score >= 4 && score <= 4.4) return 4;
    if (score >= 4.5 && score <= 4.8) return 4.5;
    if (score >= 4.9 && score <= 5) return 5;
    return 0;
  };

  const starCount = calculateStars(score);
  const handleClickOpen = () => {
    setLoading(true);
    setOpen(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownloadPDF = () => {
    const element = document.getElementById("pdf-content");
    html2pdf()
      .from(element)
      .set({ filename: `${whichyear} ${questionName}` })
      .save();
  };

  const GoToCongratulationPage = () => {
    navigate(-1);
  };

  return (
    <div>
      <Box
        sx={{
          // height: "8vh",
          backgroundColor: "#35AFFD",
        }}
      >
        <div
          style={{
            height: "10vh",
            display: "flex",
            alignItems: "center",
            padding: "25px",
            justifyContent: "center",
            fontSize: "20px",
            color: "#ffffff",
          }}
        >
          {t("Result")}
        </div>
      </Box>
      <Box
        sx={{
          marginTop: { xs: "70px", sm: "80px", md: "60px", lg: "100px", xl: "100px" },
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={Star}
            className='star'
            alt=''
            style={{ width: "100px", marginBottom: "15px" }}
          />
        </div>
        <Typography
          style={{
            textAlign: "center",
            color: "grey",
            fontSize: "20px",
          }}
        >
          {t("You Score")}
        </Typography>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "30px" }}>
          <PercentageCircle
            percentage={correctPercentage}
            filledColor='green'
            insideColor='blue'
            backgroundColor='lightgray'
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "30px", gap: "10px" }}>
          {Array.from({ length: 5 }, (_, index) => {
            if (index < Math.floor(starCount)) {
              return <StarIcon key={index} style={{ color: "gold" }} />;
            } else if (index < starCount) {
              return <StarHalfIcon key={index} style={{ color: "gold" }} />;
            } else {
              return <StarIcon key={index} style={{ color: "grey" }} />;
            }
          })}
          <Typography style={{ fontSize: "20px" }}>{score.toFixed(1)}</Typography>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "30px",
            // gap: "100px",
            width: "100%",
          }}
        >
          <ButtonGroup
            variant='outlined'
            aria-label='Loading button group'
            sx={{
              height: {
                xs: "10vh",
                sm: "18vh",
                md: "15vh",
                lg: "10vh",
                xl: "10vh",
              },
              width: {
                xs: "95%",
                sm: "84%",
                md: "57%",
                lg: "43%",
                xl: "34%",
              },
            }}
          >
            <Button
              sx={{
                width: "100%",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "10px",
                      sm: "16px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                  }}
                >
                  {t("Correct")}
                </Typography>
                <Typography
                  sx={{
                    color: "green",
                    fontSize: {
                      xs: "20px",
                      sm: "25px",
                      md: "30px",
                      lg: "30px",
                      xl: "30px",
                    },
                  }}
                >
                  {correctAnswer}
                </Typography>
              </Box>
            </Button>
            <Button sx={{ width: "100%" }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "10px",
                      sm: "16px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                  }}
                >
                  {t("Wrong")}
                </Typography>
                <Typography
                  sx={{
                    color: "red",
                    fontSize: {
                      xs: "20px",
                      sm: "25px",
                      md: "30px",
                      lg: "30px",
                      xl: "30px",
                    },
                  }}
                >
                  {wrongAnswer}
                </Typography>
              </Box>
            </Button>
          </ButtonGroup>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "30px",
            width: "100%",
            gap: "100px",
          }}
        >
          <ButtonGroup
            variant='outlined'
            aria-label='Loading button group'
            sx={{
              height: {
                xs: "10vh",
                sm: "18vh",
                md: "15vh",
                lg: "10vh",
                xl: "10vh",
              },
              width: {
                xs: "95%",
                sm: "84%",
                md: "57%",
                lg: "43%",
                xl: "34%",
              },
            }}
          >
            <Button
              sx={{
                width: "100%",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "10px",
                      sm: "16px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                    textWrap: "nowrap",
                  }}
                >
                  {t("Marks Gained")}
                </Typography>
                <Typography
                  sx={{
                    color: "green",
                    fontSize: {
                      xs: "20px",
                      sm: "25px",
                      md: "30px",
                      lg: "30px",
                      xl: "30px",
                    },
                  }}
                >
                  {marksGained}
                </Typography>
              </Box>
            </Button>
            <Button sx={{ width: "100%" }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "10px",
                      sm: "16px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                    textWrap: "nowrap",
                  }}
                >
                  {t("Previous Highest Marks")}
                </Typography>
                <Typography
                  sx={{
                    color: "#D4AF37",
                    fontSize: {
                      xs: "20px",
                      sm: "25px",
                      md: "30px",
                      lg: "30px",
                      xl: "30px",
                    },
                  }}
                >
                  {highestMarks}
                </Typography>
              </Box>
            </Button>
          </ButtonGroup>
        </div>
        <Typography
          sx={{
            color: "grey",
            textAlign: "center",
            fontSize: {
              xs: "10px",
              sm: "16px",
              md: "18px",
              lg: "18px",
              xl: "18px",
            },
            m: 2,
          }}
        >
          {t("The Smart way to get better result")}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "25px",
            //marginTop: "10px",
            paddingBottom: "20px",
          }}
        >
          <Button
            variant='outlined'
            onClick={GoToCongratulationPage}
            sx={{
              width: "140px",
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "14px",
                lg: "14px",
                xl: "14px",
              },
            }}
          >
            {t("Continue")}
          </Button>
          <Button
            variant='contained'
            onClick={handleClickOpen}
            sx={{
              // width: "140px",
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "14px",
                lg: "14px",
                xl: "14px",
              },
            }}
          >
            {t("View Summary")}
          </Button>
        </div>
        {loading ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "20px", // Optional: Adds padding around the spinner
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
            <DialogTitle>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>{whichyear}</span>
                <span style={{ fontSize: "20px", fontWeight: "bold" }}>{questionName}</span>
              </Box>
            </DialogTitle>
            <DialogContent>
              <div id='pdf-content'>
                {userAnswers.map((answer, index) => (
                  <div key={index} style={{ marginBottom: "10px", padding: "10px" }}>
                    <Typography variant='body1'>
                      <ReactQuill
                        value={`${answer.question}`}
                        readOnly={true}
                        modules={{ toolbar: false }}
                        style={{
                          border: "0px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        className='ReactQuillContainer'
                      />
                    </Typography>
                    {answer.options.map((option, idx) => (
                      <div
                        key={idx}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid",
                          borderColor: option.isSelected
                            ? option.isCorrect
                              ? "green"
                              : "red"
                            : "grey",
                          padding: "5px",
                          marginBottom: "5px",
                          borderRadius: "4px",
                        }}
                      >
                        <strong>{option.option}:</strong>
                        <ReactQuill
                          value={option.value}
                          readOnly={true}
                          modules={{ toolbar: false }}
                          style={{
                            border: "none",
                            flex: 1,
                            marginLeft: "10px",
                          }}
                          className='ReactQuillContainer'
                        />
                      </div>
                    ))}
                    <Typography
                      variant='body2'
                      color={answer.isCorrect ? "green" : "red"}
                      sx={{ textAlign: "right" }}
                    >
                      <strong>{t("Your Answer")}:</strong> {answer.selectedAnswer} (
                      {answer.isCorrect ? t("Correct") : t("Incorrect")})
                    </Typography>
                    {!answer.isCorrect && (
                      <Typography variant='body2' color='green' sx={{ textAlign: "right" }}>
                        <strong>{t("Correct Answer")}:</strong> {answer.correctAnswer}
                      </Typography>
                    )}
                  </div>
                ))}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color='primary'>
                {t("Close")}
              </Button>
              <Button onClick={handleDownloadPDF} color='primary' variant='contained'>
                {t("Download PDF")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    </div>
  );
};

export default ExamResult;
