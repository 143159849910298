import React from "react";
import { Paper, Typography, List, ListItem, Box } from "@mui/material";
import { styled } from "@mui/system";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: "20px",
  margin: "5px 0",
  width: "100%",
  backgroundColor: "#f9f9f9",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.3)",
  borderRadius: "8px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#90CDF4",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({}));

const OptionLabel = styled(Box)(({ theme }) => ({
  fontWeight: "bold",
  marginRight: "10px",
  color: "#007bff",
  padding: "5px",
}));
const SolutionTypography = styled(Typography)(({ theme }) => ({
  color: "blue",
  fontWeight: "bold",
  fontSize: "1rem",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.2)",
  padding: "10px",
  borderRadius: "8px",
  alignItems: "center",
  textAlign: "center",
}));
const quizButton = styled(Typography)(({ theme }) => ({
  alignItems: "center",
}));

export { StyledTypography, OptionLabel, StyledListItem, SolutionTypography };
