export const APIClient = () => {
  const node_env = process.env.REACT_APP_NODE_ENV;
  const apiURL =
    node_env === "production" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_DEV_API_URL;
  return {
    API_BASE_URL: apiURL,
    API_HEADERS: {
      headers: {
        authorization: sessionStorage.getItem("stsToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  };
};
