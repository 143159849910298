import React, { useEffect, useState } from "react";
import { Typography, Card, Button, Box } from "@mui/material";
import Switch from "@mui/material/Switch";
import { CurrencyRupeeSharp } from "@mui/icons-material";
import axios from "axios";
import { APIClient } from "../../../../utils/APIClient";
import {
  changePlanName,
  changeUserID,
  CurrentFreeTrailStatus,
  CurrentPlanName,
  CurrentBoard,
  CurrentClassName,
  CurrentFrequency,
} from "../../../../redux/profileDataSlice";
import { planDetails, planDescription } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import TrailExpiry from "../../../../common/modals/TrailExpiry/TrailExpiry";
import { useNavigate, useLocation } from "react-router-dom";
import { CurrentMobileNo, CurrentEmailId, CurrentName } from "../../../../redux/registerDataSlice";
import "./NeetSubscription.css";
import { useTranslation } from "react-i18next";
import SubjectNavbar from "../../../SubjectNavbar/SubjectNavbar";
import Colors from "../../../../utils/Colors";
const NeetSubscriptionPlans = () => {
  const { t } = useTranslation(["translationNeetSubscriptionPlans"]);
  const getuserID = useSelector(changeUserID);
  const dispatch = useDispatch();
  const className = useSelector(CurrentClassName);
  const board = useSelector(CurrentBoard);
  const token = localStorage.getItem("token");
  const CurrentMob = useSelector(CurrentMobileNo);
  const CurrentNam = useSelector(CurrentName);
  const CurrentFreq = useSelector(CurrentFrequency);
  console.log(CurrentFreq, "CurrentFreq");
  const modifyPlane = useSelector(CurrentPlanName);
  const freeTrail = useSelector(CurrentFreeTrailStatus);
  const CurrentEma = useSelector(CurrentEmailId);

  const [yearly, setYearly] = useState(true);
  const handleSwitchChange = () => {
    setYearly(!yearly);
  };
  const location = useLocation();
  const [pricingDetails, setPricingDetails] = useState({ month: 0, year: 0 });
  const premium = yearly ? pricingDetails?.premium?.year || 0 : pricingDetails?.premium?.month || 0;
  const premiumPrice = premium.toLocaleString("en-IN");
  const standard = yearly
    ? pricingDetails?.standard?.year || 0
    : pricingDetails?.standard?.month || 0;
  const standardPrice = standard.toLocaleString("en-IN");
  const [neet, setNeet] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState("");
  const [getplane, setGetplane] = useState("");
  const [getPaymentFre, setGetPaymentFre] = useState("");
  const Contents = [
    t("Previous year solved questions and quizzes"),
    t("Mock exams"),
    t("Live Classes"),
    t("Pre-Upload Classes"),
  ];
  const [currentDate, setCurrentDate] = useState(new Date().toISOString());
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState();
  const handleFreeButtonClick = () => {
    let payload = {
      userId: getuserID.payload.profileData.userID,
      neetSubscription: {
        paymentDetails: {
          transactionId: "",
          amount: 0,
          paymentDate: "",
        },
        planName: "basic",
        paymentFrequency: "",
      },
    };

    const currentdate = new Date();
    axios.put(`${APIClient().API_BASE_URL}/api/updateSubscription`, payload).then((res) => {
      console.log(res);
    });

    navigate("/home");
  };
  const [openDialog, setOpenDialog] = useState(false);
  const handleChoosePlanButtonClick = (Price, plan) => {
    const newDate = new Date().toISOString();
    setCurrentDate(newDate);
    //let planePrice = Price.replace(/,|\/+/g, "");
    let planePrice = Price.toString();
    const gettingOrderId = {
      amount: planePrice,
    };
    axios.post(`${APIClient().API_BASE_URL}/api/razorpayapi`, gettingOrderId).then((res) => {
      setOrderId(res.data.orderId);
    });
    setSelectedAmount("10");
    const amount = parseFloat(Price.toString());
    initiatePayment(amount, orderId, plan);
  };
  const initiatePayment = (amount, orderId, plan) => {
    const testKeys = {
      key: "rzp_test_c6qVcdC2EOngSN",
      key_secret: "9DBOHByv5alLCVMnKgflEh7x",
    };
    // const liveKeys = {
    //   key: "rzp_live_y3TYjEL32Vj556",
    //   key_secret: "xJIERDCsOpMOmV4W8MxT9YOA",
    // };
    if (amount === "") {
      alert("Please select an amount");
    } else {
      var options = {
        ...testKeys,
        amount: amount * 100,
        currency: "INR",
        name: "Netcom",
        description: "For testing purpose",
        orderId: orderId,
        handler: function (response) {
          let payload = {
            userId: getuserID.payload.profileData.userID,
            neetSubscription: {
              paymentDetails: {
                transactionId: response.razorpay_payment_id,
                amount: amount,
                paymentDate: currentDate,
              },
              planName: plan,
              paymentFrequency: yearly ? "year" : "month",
            },
          };
          const currentdate = new Date();
          axios.put(`${APIClient().API_BASE_URL}/api/updateSubscription`, payload).then((res) => {
            const endDate = new Date(res.data.user.neetSubscription.endDate);
            if (endDate > currentdate) {
              navigate("/home");
            }
          });
        },
        prefill: {
          name: CurrentNam,
          email: CurrentEma,
          contact: CurrentMob,
        },
        notes: {
          address: "Netcom office",
        },
        theme: {
          color: "#35affc",
        },
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  };

  const onPlanBtnClick = (planName) => {
    if (token) {
      let payload = {
        userId: getuserID.payload.profileData.userID,
        neetSubscription: {
          paymentDetails: {
            transactionId: "",
            amount: 0,
            paymentDate: "",
          },
          planName: planName,
          paymentFrequency: "",
        },
      };
      axios
        .put(`${APIClient().API_BASE_URL}/api/updateSubscription`, payload, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            navigate("/home");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      console.log("neetchange", "plan2");
    }
  };

  const onUpgradeBtnClick = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${APIClient().API_BASE_URL}/api/getPlanPricing`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const data = response.data.message;
        const premiumPlan = data.find((plan) => plan.planName === "premium");
        const standardPlan = data.find((plan) => plan.planName === "standard");
        var pricingDetails;
        if (premiumPlan && standardPlan) {
          pricingDetails = {
            premium: premiumPlan.pricingDetails,
            standard: standardPlan.pricingDetails,
          };
        } else {
          pricingDetails = { premiumPlan };
        }
        setPricingDetails(pricingDetails);
      })
      .catch((error) => {
        console.error("Error fetching plan pricing:", error); // Error handling
      });
  };

  useEffect(() => {
    onUpgradeBtnClick();
  }, []);
  return (
    <>
      <div>
        {/* <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            height: "60vh",
            overflow: "hidden",
          }}
        >
          <img
            style={{ width: "110%", marginTop: "-10px" }}
            src={require("../assets/cover.png")}
            alt='Cover'
          />
          <img
            style={{
              width: "1135px",
              height: "738px",
              position: "absolute",
              marginRight: "860px",
            }}
            src={require("../assets/insideCover.png")}
          />

          <img
            src={require("../assets/insideCover2.png")}
            alt='Inside Cover'
            height='120px'
            style={{
              marginLeft: "1300px",
              marginTop: "140px",
              position: "absolute",
            }}
          />
        </div> */}
        <SubjectNavbar title={"Home"} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              color: Colors.globalColorThemeColor,
              font: "public-sans",
              // fontSize: "35px",
              fontWeight: 700,
              fontSize: { sm: "30px", md: "32px", lg: "35px", xl: "35px" },
              // backgroundColor: { sm: "red", md: "yellow", lg: "green", xl: "black" },
            }}
          >
            {t("NEET Subscription Pricing Plans")}
          </Typography>
          <Box display='flex' justifyContent={"center"} alignItems={"center"} gap={"10px"}>
            <Typography
              style={{
                color: Colors.globalColorThemeColor,
                font: "public-sans",
                fontSize: "15px",
                fontWeight: 600,
              }}
            >
              {t("Monthly")}
            </Typography>
            <Switch defaultChecked sx={{ transform: "scale(1.5)" }} onChange={handleSwitchChange} />
            <Typography
              style={{
                color: "#21A6FD",
                font: "public-sans",
                fontSize: "15px",
                fontWeight: 600,
              }}
            >
              {t("Yearly")}
            </Typography>
          </Box>
        </div>
      </div>
      <Box
        display={"flex"}
        justifyContent={"space-around"}
        flexWrap={"wrap"}
        px={2}
        gap={"50px"}
        marginTop={"50px"}
      >
        {planDetails.map((item, index) => {
          console.log(index);
          if (freeTrail.isFreeTrialUsed && item.isTrail) return null;
          console.log(freeTrail.isFreeTrialUsed);
          console.log(freeTrail.isFreeTrial);
          console.log(item.isTrail);
          console.log("here");
          const isCurrentPlan =
            modifyPlane === item.planName && CurrentFreq === (yearly ? "year" : "month");
          return (
            <Card
              key={index}
              elevation={8}
              sx={{
                height: "520px",
                width: "369px",
                background: "#ffffff",
                borderStartEndRadius: "50px",
                borderBottomLeftRadius: "50px",
                border: "2px solid #DC6B19",
                marginBottom: "15px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "25px",
                  fontWeight: 600,
                  marginTop: "70px",
                  marginLeft: "40px",
                  color: item.planName === "premium" ? "#DC6B19" : "#000",
                  textTransform: "capitalize",
                }}
              >
                {item.planName}
              </Typography>
              <Typography
                sx={{
                  fontSize: "35px",
                  color: "#000000",
                  fontWeight: 900,
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "30px",
                  visibility: item.planName === "basic" ? "hidden" : "visible",
                }}
              >
                <CurrencyRupeeSharp sx={{ width: "40px", height: "40px" }} />
                {item.planName === "premium"
                  ? premiumPrice
                  : item.planName === "standard"
                    ? standardPrice
                    : ""}
                {yearly ? (
                  <>
                    <Typography sx={{ fontSize: "25px", display: "inline" }}>{"/yr"}</Typography>
                    <Typography
                      sx={{
                        color: "#21A6FD",
                        marginLeft: "8px",
                        fontSize: "26px",
                        fontWeight: 600,
                        display: "inline",
                      }}
                    >
                      + GST
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography sx={{ fontSize: "25px", display: "inline" }}>{"/mo"}</Typography>
                    <Typography
                      sx={{
                        color: "#21A6FD",
                        marginLeft: "8px",
                        fontSize: "26px",
                        fontWeight: 600,
                        display: "inline",
                      }}
                    >
                      + GST
                    </Typography>
                  </>
                )}
              </Typography>
              <ul
                style={{
                  paddingLeft: "20px",
                  marginTop: "20px",
                  marginLeft: "20px",
                }}
              >
                {Contents.map((content, index) => (
                  <li
                    key={index}
                    style={{
                      listStyle: "none",
                      marginBottom: "25px",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: "10px" }}>{content}</span>
                  </li>
                ))}
              </ul>
              <Button
                variant='contained'
                onClick={() => {
                  item.planName === "basic"
                    ? onPlanBtnClick("basic")
                    : item.isTrail && item.planName === "premium"
                      ? onPlanBtnClick("premium")
                      : item.planName === "standard"
                        ? handleChoosePlanButtonClick(standard, "standard")
                        : handleChoosePlanButtonClick(premium, "premium");
                }}
                sx={{
                  height: "50px",
                  borderEndStartRadius: "17px",
                  marginLeft: "90px",
                  marginTop: "30px",
                  fontSize: t("Try 30 Days Free Trial") === "30 நாள் இலவச சோதனை" ? "13px" : "16px",
                  fontWeight: 600,
                  textTransform: "none",
                  // background: "#DC6B19",
                  // ...(t("Try 30 Days Free Trial") === "30 நாள் இலவச சோதனை" && {
                  //   maxWidth: "200px",
                  // }),
                  // background: "#DC6B19",
                  // background: "#DC6B19",
                  backgroundColor: item.isTrail === true ? "#DC6B19" : Colors.globalColorThemeColor,
                  minWidth: "150px",
                }}
                disabled={isCurrentPlan}
              >
                {isCurrentPlan
                  ? "Current Plan"
                  : item.planName === "basic"
                    ? "Free"
                    : item.isTrail
                      ? "Try 30 days Free Trail"
                      : "Choose Plan"}
              </Button>
            </Card>
          );
        })}
      </Box>
      <TrailExpiry openDialog={openDialog} />
    </>
  );
};

export default NeetSubscriptionPlans;
