import { createSlice } from "@reduxjs/toolkit";

const profileDataSlice = createSlice({
  name: "profileData",
  initialState: {
    mobileNo: "",
    name: "",
    emailID: "",
    className: "",
    board: "",
    userID: "",
    planName: "",
    paymentFrequency: "",
    medium: "",
    freeTrail: "",
  },
  reducers: {
    changeMobileNo: (state, action) => {
      state.mobileNo = action.payload;
    },
    changeName: (state, action) => {
      state.name = action.payload;
    },
    changeEmail: (state, action) => {
      state.emailID = action.payload;
    },
    changeClassName: (state, action) => {
      state.className = action.payload;
    },
    changeBoard: (state, action) => {
      state.board = action.payload;
    },
    changeUserID: (state, action) => {
      state.userID = action.payload;
    },
    changePlanName: (state, action) => {
      state.planName = action.payload;
    },
    changeMedium: (state, action) => {
      state.medium = action.payload;
    },

    changePaymentFrequency: (state, action) => {
      state.paymentFrequency = action.payload;
    },
    changeFreeTrailStatus: (state, action) => {
      state.freeTrail = action.payload;
    },
  },
});

export const {
  changeMobileNo,
  changeName,
  changeEmail,
  changeClassName,
  changeBoard,
  changeUserID,
  changePlanName,
  changePaymentFrequency,
  changeMedium,
  changeFreeTrailStatus,
} = profileDataSlice.actions;

// Selectors for the profile data
export const CurrentMobileNo = (state) => state.profileData.mobileNo;
export const CurrentName = (state) => state.profileData.name;
export const CurrentEmailId = (state) => state.profileData.emailID;
export const CurrentClassName = (state) => state.profileData.className;
export const CurrentBoard = (state) => state.profileData.board;
export const CurrentUserID = (state) => state.profileData.userID;
export const CurrentPlanName = (state) => state.profileData.planName;
export const CurrentFrequency = (state) => state.profileData.paymentFrequency;
export const CurrentMedium = (state) => state.profileData.medium;
export const CurrentFreeTrailStatus = (state) => state.profileData.freeTrail;
export default profileDataSlice.reducer;
