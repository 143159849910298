import React, { useState, useRef, useEffect } from "react";
import "./NeetExamQuizPage.css";
import { AppBar, Button, Card, Typography } from "@mui/material";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";

export default function NeetExamQuizPage() {
  const Ref = useRef(null);

  const [timer, setTimer] = useState("00:00:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds),
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:00:10");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 10);
    return deadline;
  };
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const onClickReset = () => {
    clearTimer(getDeadTime());
  };
  return (
    <>
      <div className='container'>
        <AppBar
          className='quiz-appbar'
          style={{ backgroundColor: "#35affd", height: "100px" }}
        ></AppBar>
      </div>
      <div
        className='container-timer'
        style={{ textAlign: "left", padding: "10px 150px", margin: "100px" }}
      >
        <div
          className='btn-timer'
          style={{ display: "flex", flexDirection: "column", float: "left" }}
        >
          <Button
            className='timer-section'
            onClick={onClickReset}
            style={{
              border: "1px solid #BCE4FE",
              borderRadius: "100%",
              color: "#66BEFD",
              backgroundColor: "#BCE4FE",
            }}
          >
            <PauseOutlinedIcon />
          </Button>
        </div>
        <div className='timer-option'>
          <h2>{timer}</h2>
          <h3>NEET Exam-Physics-Quiz page</h3>
        </div>
      </div>
      <div className='quiz-container'>
        <div className='quiz-cardsections'>
          <div className='exam-section'>
            <Typography variant='h3' className='exam-questions'>
              1. The World only Floating national park, Kelbul Lamjao National Park, is located in
            </Typography>
          </div>
          <div className='quiz-cards'>
            <Card className='quiz-maincard' elevation={7}>
              <Typography variant='h5' className='ans-list'>
                A.Negaland
              </Typography>
            </Card>
          </div>
          <div className='quiz-cards'>
            <Card className='quiz-maincard' elevation={7}>
              <Typography variant='h5' className='ans-list'>
                B.Tripura
              </Typography>
            </Card>
          </div>
          <div className='quiz-cards'>
            <Card className='quiz-maincard' elevation={7}>
              <Typography variant='h5' className='ans-list'>
                C.Manipur
              </Typography>
            </Card>
          </div>
          <div className='quiz-cards'>
            <Card className='quiz-maincard' elevation={7}>
              <Typography variant='h5' className='ans-list'>
                D.Assam
              </Typography>
            </Card>
          </div>
        </div>
      </div>
      <div className='container'>
        <Button
          variant='contained'
          className='next-btn'
          style={{
            textTransform: "capitalize",
            fontSize: "18px",
            fontWeight: "bold",
            backgroundColor: "#5DA6FC",
          }}
        >
          Next
        </Button>
      </div>
    </>
  );
}
