import jwtDecode from "jwt-decode";

export const tokenVerify = () => {
  try {
    const decodedToken = jwtDecode(localStorage.getItem("token"));
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (decodedToken.exp < currentTimestamp) {
      // console.log('Token has expired');
      return false;
    } else {
      // Token is still valid
      // console.log('Token is valid');
      return true;
      // Continue with your logic
    }
  } catch (e) {
    return false;
  }
};
