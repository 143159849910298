import { Stack, Box, Button, Typography, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import ContactLogo from "./images/contactLogo.png";
import axios from "axios";
import { APIClient } from "../../utils/APIClient";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "animate.css";
import Colors from "../../utils/Colors";
import { tokenVerify } from "../../utils/Helpers";
import { useTranslation } from "react-i18next";
function ContactUs() {
  const navigate = useNavigate();
  const { t } = useTranslation(["translationContactUS"]);
  const location = useLocation();
  const state = location.state;
  const { loginData } = state ? state : false;

  const [messageData, setMessageData] = useState({
    text: "",
    errormsg: "",
    isValid: false,
  });

  const handleChangeField = (value) => {
    setMessageData((previous) => ({
      ...previous,
      text: value,
    }));
    if (value) {
      setMessageData((previous) => ({
        ...previous,
        errormsg: "",
        isValid: false,
      }));
    }
  };

  const submitFeedbackFn = () => {
    if (messageData.text === "") {
      setMessageData((previous) => ({
        ...previous,
        errormsg: "Please Enter Your Message",
        isValid: true,
      }));
    } else {
      var dataToSent = {
        mob_no: loginData.mob_no,
        message: messageData.text,
      };
      axios.post(`${APIClient().API_BASE_URL}/api/netSmart_feedback`, dataToSent).then((res) => {
        Swal.fire({
          icon: "success",
          confirmButtonColor: Colors.globalColorThemeColor,
          title: res.data.message,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      });
      setMessageData({
        text: "",
        errormsg: "",
        isValid: false,
      });
    }
  };

  useEffect(() => {
    const isValidToken = tokenVerify();
    if (!state || !isValidToken) {
      navigate("/contactus");
    }
  }, []);

  return (
    <>
      <Box>
        <SubjectNavbar title={"Contact US"} />
        <Stack alignItems={"center"} mt={{ xs: 10, sm: 12, md: 15 }}>
          <Box sx={{ width: "89%" }}>
            <Stack alignItems={"flex-end"}>
              <Button
                variant='outlined'
                onClick={() => {
                  window.open("tel:7305023044", "_self");
                }}
                sx={{
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  borderColor: "black",
                  color: "black",
                  ":hover": { borderColor: "black" },
                }}
              >
                <Box
                  component={"img"}
                  src={ContactLogo}
                  sx={{ width: "25px", height: "24px", mr: 1 }}
                />{" "}
                {t("Call Us")}
              </Button>
            </Stack>
            <Typography
              sx={{
                color: Colors.globalColorThemeColor,
                fontStyle: "normal",
                fontWeight: "600px",
                fontSize: { xs: "25px", sm: "30px" },
                mt: 3,
              }}
            >
              {t("For any feedback, message here")}
            </Typography>
            <TextField
              value={messageData.text}
              helperText={messageData.errormsg}
              error={messageData.isValid}
              autoFocus
              multiline
              rows={20}
              placeholder={t("Enter your message")}
              onChange={(e) => handleChangeField(e.target.value)}
              sx={{
                mt: 3,
                width: "100%",
                borderRadius: "15px",
                borderColor: "black",
                "& label.Mui-focused": {
                  color: Colors.globalColorThemeColor,
                },

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "gray",
                    borderRadius: "5px",
                  },
                  "&:hover fieldset": {
                    borderColor: messageData.isValid ? "#D32F2F" : Colors.globalColorThemeColor,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: messageData.isValid ? "#D32F2F" : Colors.globalColorThemeColor,
                  },
                },
              }}
            />
            <Stack alignItems={"center"} mt={3}>
              <Button
                onClick={submitFeedbackFn}
                variant='contained'
                sx={{
                  borderRadius: "10px",
                  textTransform: "capitalize",
                  bgcolor: Colors.globalColorThemeColor,
                  ":hover": { bgcolor: Colors.globalColorThemeColor },
                }}
              >
                {t("Submit Feedback")}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
}

export default ContactUs;
