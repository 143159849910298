import { createSlice } from "@reduxjs/toolkit";

const loginDataSlice = createSlice({
  name: "loginData",
  initialState: {
    mobileno: "",
  },
  reducers: {
    changeMobileNo: (state, action) => {
      state.mobileno = action.payload;
    },
  },
});

export const { changeMobileNo } = loginDataSlice.actions;

export const CurrentMobileNo = (state) => state.loginData.mobileno;

export default loginDataSlice.reducer;
