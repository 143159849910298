import React, { useEffect, useState } from "react";
import HeaderBox from "../../../common/components/HeaderBox";
import { Pagination } from "@mui/material";
import Colors from "../../../utils/Colors";
import axios from "axios";
import { APIClient } from "../../../utils/APIClient";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
const PreuploadClass = ({ loginData, subjectname, subjectColor, currentPage, setCurrentPage }) => {
  const [sbPreUploadClass, setSbPreUploadClass] = useState();
  const [filterSbPreUploadClass, setFilterSbPreUploadClass] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);
  const [currentVideoLink, setCurrentVideoLink] = useState("");
  const { t } = useTranslation(["translationPreuploadClass"]);
  const [searchValue, setSearchValue] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  useEffect(() => {
    const payload = {
      page: 1,
      itemsPerPage: 5,
      subject: subjectname,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/getAllPreuploadClassForUser`, payload, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setSbPreUploadClass(res.data.data);
        setFilterSbPreUploadClass(res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching Preupload classes:", error);
      });
  }, []);
  const handlePlayClick = (videoLink) => {
    setCurrentVideoLink(videoLink);
    setVideoDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setVideoDialogOpen(false);
    setCurrentVideoLink("");
  };

  // const filterSbPreuploedClass = () => {
  //   if (!searchValue) {
  //     setFilterSbPreUploadClass(sbPreUploadClass);
  //     return;
  //   }
  //   let filteredValues = sbPreUploadClass.filter((item) =>
  //     item.title.toLowerCase().includes(searchValue.toLowerCase()),
  //   );
  //   setFilterSbPreUploadClass(filteredValues);
  // };
  const changeCPage = (id) => {
    setCurrentPage(id);
  };

  const recordsPerPage = 2;
  const npage = Math.ceil(filterSbPreUploadClass.length / recordsPerPage);
  const paginatedData = filterSbPreUploadClass.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage,
  );
  const filterSbPreuploedClass = () => {
    if (!sbPreUploadClass || !Array.isArray(sbPreUploadClass)) {
      // Handle the case where sbPreUploadClass is undefined or not an array
      setFilterSbPreUploadClass([]);
      return;
    }

    if (!searchValue) {
      setFilterSbPreUploadClass(sbPreUploadClass);
      return;
    }

    let filteredValues = sbPreUploadClass.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase()),
    );
    setFilterSbPreUploadClass(filteredValues);
  };

  const unlock = () => {
    navigate("/stateboardsubscription");
  };
  useEffect(() => {
    filterSbPreuploedClass();
  }, [searchValue]);

  return (
    <>
      <HeaderBox
        bgcolor={subjectColor}
        title={t("Pre Uploaded Classes")}
        searchNeeded
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        className={loginData.className}
      />
      {paginatedData && paginatedData.length > 0 ? (
        paginatedData.map((items, index) => (
          <Card
            key={index}
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "10px",
              height: "180px",
              width: "100%",
              marginBottom: "10px",
              border: "1px solid #ccc",
              boxSizing: "border-box",
              borderRadius: "5px",
            }}
          >
            {/* <CardMedia
              component='img'
              sx={{
                width: { xs: "100%", sm: "120px" },
                height: { xs: "auto", sm: "auto" },
                objectFit: "cover",
                "@media (max-width: 600px)": {},
              }}
              image={items.videoImg}
              alt='placeholder'
            /> */}
            <Box
              className='preuploadimgtagbox'
              // sx={{
              //   backgroundColor: { xs: "blue", sm: "viloet", md: "red", lg: "red", xl: "red" },
              //   height: "100%",
              //   width: { xs: "20%", sm: "20%", md: "14%", lg: "14%", xl: "14%" },
              // }}
            >
              <img
                src={items.videoImg}
                // style={{ width: "100%", height: "40%" }}
                className='preuploadimgtag'
              />
            </Box>
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                // flexDirection: "column",
                flexWrap: "wrap",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    opacity: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography
                      sx={{
                        marginRight: "10px",
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      {t("Title")}
                    </Typography>
                    <Typography
                      sx={{
                        minWidth: "20vw",
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                      variant='body3'
                    >
                      {items.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      fontWeight: "bold",
                      marginLeft: {
                        xs: "10px",
                        sm: "-8px",
                        md: "20px",
                        lg: "20px",
                        xl: "20px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        marginRight: "10px",
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      {t("Subject")}
                    </Typography>
                    <Typography
                      sx={{
                        minWidth: "10vw",
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                      variant='body3'
                    >
                      {items.subject}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    fontWeight: "bold",
                  }}
                >
                  <Typography
                    sx={{
                      marginRight: "10px",
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                    }}
                  >
                    {t("Description")}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* <Typography
                      sx={{
                        maxWidth: "65vw",
                        lineHeight: "1.2",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        opacity: 0.5,
                        wordBreak: "break-word",
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                      variant='body3'
                    >
                      {items.desc.length > 100 ? `${items.desc.slice(0, 100)}...` : items.desc}
                    </Typography>
                    {items.desc.length > 100 && (
                      <Tooltip title={items.desc} placement='bottom-end'>
                        <Typography
                          gutterBottom
                          color={Colors.globalColorThemeColor}
                          sx={{
                            lineHeight: "1.2",
                            marginBottom: 0,
                            cursor: "pointer",
                            alignSelf: "flex-start",
                            width: "100px",
                            textAlign: "right",
                            textWrap: "nowrap",
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "15px",
                              lg: "15px",
                              xl: "15px",
                            },
                          }}
                        >
                          {t("Read more")}
                        </Typography>
                      </Tooltip>
                    )} */}
                    <Typography
                      sx={{
                        maxWidth: "65vw",
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        lineHeight: "1.2",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        opacity: 0.5,
                        wordBreak: "break-word", // Ensures long words break and wrap within the container
                        whiteSpace: "normal", // Allows the text to wrap normally
                      }}
                    >
                      {/* Display first 50 characters of desc */}
                      {items.desc.length > 50 ? `${items.desc.slice(0, 50)}...` : items.desc}

                      {/* Tooltip button to read more */}
                      {items.desc.length > 50 && (
                        <Tooltip
                          title={items.desc}
                          placement='bottom-end'
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          {/* "Read more" button styled as inline text */}
                          <Typography
                            component='span'
                            color={Colors.globalColorThemeColor}
                            sx={{
                              cursor: "pointer",
                              fontSize: {
                                xs: "12px",
                                sm: "12px",
                                md: "15px",
                                lg: "15px",
                                xl: "15px",
                              },
                            }}
                          >
                            &nbsp;Read more
                          </Typography>
                        </Tooltip>
                      )}
                    </Typography>
                  </Box>
                  {/* <Typography
                    sx={{
                      marginRight: "10px",
                      fontSize: {
                        // xs: "blue",
                        sm: "12px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                    }}
                  >
                    Description
                  </Typography>
                  <Typography
                    sx={{
                      maxWidth: "65vw",
                      lineHeight: "1.2",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      textOverflow: "ellipsis",
                      opacity: 0.5,
                      wordBreak: "break-word",
                      fontSize: {
                        // xs: "blue",
                        sm: "12px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                    }}
                    variant='body3'
                  >
                    {items.desc}
                  </Typography> */}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  opacity: 0.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    fontWeight: "bold",
                  }}
                >
                  <Typography
                    sx={{
                      marginRight: "10px",
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                    }}
                  >
                    {t("Author")}
                  </Typography>
                  <Typography
                    sx={{
                      minWidth: "20vw",
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                    }}
                    variant='body3'
                  >
                    {items.author}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    fontWeight: "bold",
                    marginLeft: {
                      xs: "10px",
                      sm: "-8px",
                      md: "20px",
                      lg: "20px",
                      xl: "20px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      marginRight: "10px",
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                    }}
                  >
                    {t("Date")}
                  </Typography>
                  <Typography
                    sx={{
                      minWidth: "10vw",
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                    }}
                    variant='body3'
                  >
                    {dayjs(items.date).format("DD/MM/YYYY hh:mm A")}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
            {items.isLocked === false ? (
              <CardActions
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "10px",
                  marginLeft: "auto",
                  // "@media (max-width: 1024px)": {
                  //   justifyContent: "center",
                  // },
                }}
              >
                <Button
                  variant='contained'
                  size='small'
                  onClick={() => handlePlayClick(items.videoLink)}
                  fullWidth
                  sx={{
                    // width: "90%",
                    // marginLeft: "8px",
                    backgroundColor: subjectColor,
                    "&:hover": {
                      backgroundColor: subjectColor, // keep the same color on hover
                    },
                    // padding: "4px 30px",
                    padding: {
                      xs: "4px 15px",
                      sm: "4px 30px",
                      md: "4px 30px",
                      lg: "4px 30px",
                      xl: "4px 30px",
                    },

                    // fontSize: "13px",
                    fontSize: {
                      xs: "10px",
                      sm: "13px",
                      md: "13px",
                      lg: "13px",
                      xl: "13px",
                    },
                  }}
                >
                  {t("play")}
                </Button>
              </CardActions>
            ) : (
              <CardActions
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginLeft: "auto",
                }}
              >
                <Button
                  variant='contained'
                  size='small'
                  onClick={unlock}
                  fullWidth
                  sx={{
                    backgroundColor: "#DAA520",
                    fontSize: {
                      xs: "10px",
                      sm: "13px",
                      md: "13px",
                      lg: "13px",
                      xl: "13px",
                    },
                    // padding: "4px 10px",
                    padding: {
                      xs: "4px 4px",
                      sm: "4px 10px",
                      md: "4px 10px",
                      lg: "4px 10px",
                      xl: "4px 10px",
                    },
                    "&:hover": {
                      backgroundColor: "#DAA520", // keep the same color on hover
                    },
                  }}
                  startIcon={<LockIcon />}
                >
                  {t("unlock")}
                </Button>
              </CardActions>
            )}

            <Dialog
              open={videoDialogOpen}
              onClose={handleCloseDialog}
              fullWidth={true} // Ensures full width is used
              maxWidth='lg' // Set a larger maximum width for landscape mode
              PaperProps={{
                sx: {
                  width: "100%",
                  height: "auto",
                  "@media (orientation: landscape) and (max-height: 400px)": {
                    height: "100vh", // Make the dialog height fill the viewport on landscape mode
                    maxHeight: "100vh",
                  },
                },
              }}
            >
              <DialogTitle>
                Play Video
                <IconButton
                  aria-label='close'
                  onClick={handleCloseDialog}
                  sx={{ position: "absolute", right: 8, top: 8 }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent
                sx={{
                  backgroundColor: "black",
                  padding: 0,
                  height: "auto",
                  "@media (orientation: landscape) and (max-height: 400px)": {
                    padding: 0,
                    height: "100%", // Full height of the dialog
                  },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    width: "100%", // Full width in landscape
                    paddingTop: "56.25%", // Aspect ratio to maintain 16:9
                    height: "auto",
                    "@media (orientation: landscape) and (max-height: 400px)": {
                      paddingTop: "56.25%", // Maintain 16:9 aspect ratio for video
                      height: "auto",
                    },
                  }}
                >
                  <ReactPlayer
                    url={currentVideoLink}
                    width='100%'
                    height='100%'
                    style={{
                      position: "absolute", // Make the player fill the box
                      top: 0,
                      left: 0,
                    }}
                  />
                </Box>
              </DialogContent>
            </Dialog>
          </Card>
        ))
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p style={{ fontWeight: "bold" }}>{t("No Preupload Classes available.")}</p>
        </div>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Pagination
          count={npage}
          page={currentPage}
          onChange={(e, i) => changeCPage(i)}
          color='primary'
        />
      </Box>
    </>
  );
};

export default PreuploadClass;
