import React, { useEffect, useState } from "react";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import Card from "@mui/material/Card";
import "./unitstyle.css";
import { Stack, Typography, CircularProgress, Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { APIClient } from "../../utils/APIClient";
import axios from "axios";
import { setUnitsData, CurrentUnitsData } from "../../redux/unitsDataSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  setUnitTopics,
  CurrentBookmarkUnitTopics,
  setBookmarkUnitTopics,
} from "../../redux/unitTopicsSlice";
import { setUnitQAs, CurrentUnitQAs, setQAs } from "../../redux/unitQAsSlice";
import { setUnitMCQs, currentUnitMCQs, setUnitsBalanceMCQs } from "../../redux/unitMCQsSlice";
import arrayShuffle from "array-shuffle";
import wpicon from "../Units/Image/whatsapp.svg";
import Colors from "../../utils/Colors.js";
import { CurrentBoard } from "../../redux/registerDataSlice";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { tokenVerify } from "../../utils/Helpers";
import { useTranslation } from "react-i18next";
import UnitDetails from "./components/UnitDetails/UnitDetails.js";
import HeaderBox from "../../common/components/HeaderBox.js";
import Loader from "../../common/components/Loader";
import PreuploadClass from "../Subjectpage/PreuploadClass/PreuploadClass";
function Units() {
  const { t } = useTranslation(["translationUnits"]);
  const navigate = useNavigate();
  const unitsData = useSelector(CurrentUnitsData);
  const currentBoards = useSelector(CurrentBoard);
  const unitQAs = useSelector(CurrentUnitQAs);
  const unitMCQs = useSelector(currentUnitMCQs);
  const bookmarkUnitTopics = useSelector(CurrentBookmarkUnitTopics);
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;
  const loginData = location.state ? state.loginData : false;
  const subjectname = location.state ? state.subjectname : false;
  const subjectId = location.state ? state.subjectId : false;
  const subjectColor = location.state ? state.subjectColor : false;
  const [buttonClicked, setButtonClicked] = useState("video");
  const [findTopic, setFindTopic] = useState();
  const [unitList, setUnitList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [qaList, setQaList] = useState([]);
  const [mcqList, setMcqList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tabSelected, setTabSelected] = useState("video");
  const [loading, setLoading] = useState(true);
  const handleBackBtnClick = (e) => {
    setFindTopic("");
  };
  const handleTabBtnClick = (e) => {
    if (e === "video") {
      setButtonClicked(e);
      setTabSelected(e);
    } else if (e === "qa") {
      setButtonClicked(e);
      setTabSelected(e);
    } else if (e === "quiz") {
      setButtonClicked(e);
      setTabSelected(e);
    }
  };
  const [color, setcolor] = useState();
  const getVideos = (unitId) => {
    const payload = {
      unitId: unitId,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/getVideoForUser`, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setVideoList(res.data.data);
      });
  };
  const getMcq = (unitId) => {
    const payload = {
      page: 0,
      itemsPerPage: 0,
      unitId,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/getAllMCQsForUser`, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setMcqList(res.data.data);
      });
  };
  const getQa = (unitId) => {
    const payload = {
      page: 0,
      itemsPerPage: 0,
      unitId,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/getAllQAsForUser`, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setQaList(res.data.data);
      });
  };

  const handleWhatsapp = () => {
    const message = subjectname;
    const name = loginData.name;
    const mobNo = loginData.mob_no;
    window.open(
      `https://wa.me/7397730950?text=Hello!!! %0AI'm ${name}%0ASubject: ${message}%0AMobile Number: ${mobNo.slice(
        3,
        13,
      )}`,
    );
  };
  const getUnits = () => {
    setLoading(true);
    const payload = {
      subjectId,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/getUnitsForUser`, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        setUnitList(res.data.data);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onUnitClick = (index, unitId, unitName) => {
    setLoading(true);
    setVideoList([]);
    setMcqList([]);
    setQaList([]);
    getVideos(unitId);
    getMcq(unitId);
    getQa(unitId);
    setFindTopic(index);

    // dispatch(setBookmarkUnitTopics({}));
    setcolor(index);
    setLoading(false);
    // var unittopics = unitsData.unitTopics[index];
    // dispatch(setUnitTopics(unittopics));
    // var filterqasdata = unitQAs[index.toString()];
    // dispatch(setQAs(filterqasdata));
    // var filtermcqsdata = arrayShuffle(unitMCQs[0].mcqData[index]);
    // dispatch(setUnitsBalanceMCQs(filtermcqsdata.slice(0, 10)));
  };

  useEffect(() => {
    const isValidToken = tokenVerify();
    if (!state || !isValidToken) {
      navigate("/home");
      return;
    }
    getUnits();
  }, []);

  return (
    <>
      <Box>
        <div className='colorChanged' style={{ "--text-color": subjectColor }}>
          <SubjectNavbar title={"units"} subjectColor={subjectColor} />
        </div>
        {unitsData ? (
          <div>
            <div className='units' style={{ "--text-color": subjectColor }}>
              <div className='unitsDivs'>
                <div className='unitspartname'>
                  <Card class='unitCard'>{subjectname}</Card>
                  <Typography class='unitTypo'>
                    {unitList?.length} {t("UNITS")}
                  </Typography>
                </div>
              </div>
            </div>
            {!findTopic && (
              <Box sx={{ padding: "10px 50px" }}>
                <HeaderBox bgcolor={subjectColor} title={t(`Back to Subjects`)} backBtn={true} />
              </Box>
            )}

            <div className='unitsSubjectsAndVideos'>
              <div className='secondDivUnitsSubjectsAndVideos'>
                {!findTopic ? (
                  <div className='unitSubSide'>
                    {loading ? (
                      <Stack alignItems='center' justifyContent='center' sx={{ height: "100vh" }}>
                        <CircularProgress />
                      </Stack>
                    ) : unitList?.length ? (
                      unitList.map((items, index) => (
                        <Card
                          key={index}
                          elevation={9}
                          className='unitsCards'
                          sx={{
                            borderRadius: "15px",
                            backgroundColor: "white",
                            "&:hover": {
                              backgroundColor: "lightgray",
                              border: `1px solid ${subjectColor}`,
                            },
                          }}
                          onClick={() => onUnitClick(index + 1, items.unitId, items.unitName)}
                        >
                          <p className='rollno' style={{ "--text-color": subjectColor }}>
                            {t("Unit")} {index + 1}
                          </p>
                          <h3 className='unitheading' style={{ color: "black" }}>
                            {items.unitName}
                          </h3>
                          <ChevronRightIcon
                            className='aerrowFordIcon'
                            sx={{ color: "black", mr: 1 }}
                          />
                        </Card>
                      ))
                    ) : (
                      <div
                        style={{
                          color: "black",
                          height: "80vh",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {t("No Data Found")}
                      </div>
                    )}
                  </div>
                ) : (
                  <UnitDetails
                    tabSelected={tabSelected}
                    subjectColor={subjectColor}
                    buttonClicked={buttonClicked}
                    handleTabBtnClick={handleTabBtnClick}
                    handleBackBtnClick={handleBackBtnClick}
                    color={color}
                    loginData={loginData}
                    subjectname={subjectname}
                    unitName={unitList[findTopic - 1].unitName}
                    unitNo={findTopic}
                    mcqList={mcqList}
                    qaList={qaList}
                    videoList={videoList}
                    unitList={unitList}
                  />
                )}
              </div>
              {/*               
                <div style={{ padding: "10px 50px" }}>
                  <PreuploadClass
                    loginData={loginData}
                    subjectname={subjectname}
                    subjectColor={subjectColor}
                  />
                </div> */}

              {!findTopic && (
                <div style={{ padding: "10px 50px" }}>
                  <PreuploadClass
                    loginData={loginData}
                    subjectname={subjectname}
                    subjectColor={subjectColor}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              )}
            </div>
            <Box
              component={"img"}
              src={wpicon}
              onClick={handleWhatsapp}
              sx={{
                cursor: "pointer",
                borderRadius: "50px",
                position: "fixed",
                bottom: "10px",
                right: "20px",
              }}
            />
          </div>
        ) : (
          <Stack alignItems={"center"} justifyContent={"center"} sx={{ height: "100vh" }}>
            <CircularProgress />
            <Typography mt={2}>Loading Please Wait...</Typography>
          </Stack>
        )}
      </Box>
    </>
  );
}

export default Units;
