import React, { useState, useEffect } from "react";
import { Button, Stack } from "@mui/material";
import ReactQuill from "react-quill";
import { StyledTypography, StyledListItem, SolutionTypography } from "../../muistyle/quiz";
import Result from "./Result";
import { useTranslation } from "react-i18next";

function EasyMediumHard({ mcqDataforQuiz }) {
  const { t } = useTranslation(["translationEasyMediumHard"]);
  const unitMCQData = mcqDataforQuiz;
  const [indexValue, setIndexValue] = useState(0);
  const [answerButton, setAnswerButton] = useState(true);
  const [answervalidation, setanswervalidation] = useState(false);
  const [selectionColor, setSelectionColor] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("#8BC5EA");
  const [selectBgColor, setSelectBgColor] = useState("");
  const [validationCheck, setValidationCheck] = useState("");
  const [isUserSelectedAnswer, setIsUserSelectedAnswer] = useState(false);
  const [showDiv1, setShowDiv1] = useState(true);
  const [showDiv2, setShowDiv2] = useState(false);
  const [duration, setDuration] = useState(0);
  const [endingDuration, setEndingDuration] = useState();
  const questionTimerSeconds = 60;

  const startTime = new Date();

  const optionObj = {
    0: "A",
    1: "B",
    2: "C",
    3: "D",
  };

  const answerClick = (index) => {
    if (isUserSelectedAnswer) {
      return;
    }
    setAnswerButton(false);
    setSelectionColor(index);
    setValidationCheck(optionObj[index]);
    setBackgroundColor("#8BC5EA");
  };

  const [resultGreen, setResultGreen] = useState([]);

  const answerValidate = () => {
    setIsUserSelectedAnswer(true);
    setanswervalidation(true);
    if (validationCheck === unitMCQData[indexValue].answer) {
      setResultGreen([...resultGreen, unitMCQData[indexValue].answer]);
      setBackgroundColor("#9AE6B4");
    } else {
      setBackgroundColor("#FC8181");
      setSelectBgColor(unitMCQData[indexValue].answer);
    }
  };

  const nextBtnFun = () => {
    if (indexValue !== unitMCQData.length - 1) {
      setIsUserSelectedAnswer(false);
      setIndexValue(indexValue + 1);
      setanswervalidation(false);
      setAnswerButton(true);
      setBackgroundColor("#8BC5EA");
      setSelectionColor(null);
      setSelectBgColor("#FC8181");
    } else {
      setShowDiv1(false);
      setShowDiv2(true);
      setEndingDuration(duration);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const diffTime = Math.floor((currentTime - startTime) / 1000);
      setDuration(diffTime);
      if (diffTime > questionTimerSeconds) {
        clearInterval(interval);
        setShowDiv1(false);
        setShowDiv2(true);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [startTime]);

  return (
    <>
      {showDiv1 && (
        <div
          style={{
            padding: "5px",
          }}
        >
          {/* <StyledTypography>
            Time left: {Math.max(questionTimerSeconds - duration, 0)} seconds
          </StyledTypography> */}
          <StyledTypography style={{ fontSize: "30px" }}>
            {t("Question No")} : {indexValue + 1}/{unitMCQData.length}
          </StyledTypography>
          <ReactQuill
            value={unitMCQData[indexValue]["question"]}
            readOnly={true}
            modules={{ toolbar: false }}
            style={{ width: "100%", border: "none" }}
          />
          <div style={{ marginTop: "16px" }}>
            {Object.keys(optionObj).map((optIndex) => (
              <StyledListItem
                key={optIndex}
                onClick={() => answerClick(optIndex)}
                sx={{
                  backgroundColor: () => {
                    if (selectionColor === optIndex) {
                      return backgroundColor;
                    } else if (
                      answervalidation &&
                      optionObj[optIndex] === unitMCQData[indexValue].answer
                    ) {
                      return "#9AE6B4";
                    } else if (answervalidation && optionObj[optIndex] === validationCheck) {
                      return "#FC8181";
                    } else {
                      return "white";
                    }
                  },
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <strong>{optionObj[optIndex]}:</strong>
                  <ReactQuill
                    value={unitMCQData[indexValue][`option${parseInt(optIndex) + 1}`]}
                    readOnly={true}
                    modules={{ toolbar: false }}
                    style={{ marginLeft: "8px", border: "none" }}
                  />
                </div>
              </StyledListItem>
            ))}
          </div>
          <Stack
            mt={2}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {answervalidation ? (
              <Button
                variant='contained'
                onClick={nextBtnFun}
                sx={{ width: "50%", marginBottom: { xs: "10px" } }}
              >
                {t("Next")}
              </Button>
            ) : (
              <Button
                color='success'
                variant='contained'
                onClick={answerValidate}
                disabled={answerButton}
                sx={{ width: "50%" }}
              >
                {t("Submit")}
              </Button>
            )}
          </Stack>
          {isUserSelectedAnswer && (
            <SolutionTypography variant='h6'>
              {t("Solution")}: {unitMCQData[indexValue].answer}
            </SolutionTypography>
          )}
        </div>
      )}
      {showDiv2 && (
        <Result
          className=''
          totalQuestions={unitMCQData.length}
          correctAnswers={resultGreen.length}
          duration={endingDuration}
          mcqDataforQuiz={mcqDataforQuiz}
        />
      )}
    </>
  );
}

export default EasyMediumHard;
