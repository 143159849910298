import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { combineReducers } from "redux"; // Import combineReducers

import registerDataReducer from "./registerDataSlice";
import loginDataReducer from "./loginDataSlice";
import unitsDataReducer from "./unitsDataSlice";
import unitTopicsReducer from "./unitTopicsSlice";
import unitQAsReducer from "./unitQAsSlice";
import unitMCQsReducer from "./unitMCQsSlice";
import profileDataReducer from "./profileDataSlice";

// Redux persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["registerData", "loginData", "profileData"],
};

// Combine all reducers into a root reducer function using combineReducers
const rootReducer = combineReducers({
  registerData: registerDataReducer,
  loginData: loginDataReducer,
  profileData: profileDataReducer,
  unitsData: unitsDataReducer,
  unitTopics: unitTopicsReducer,
  unitQAs: unitQAsReducer,
  unitMCQs: unitMCQsReducer,
});

// Apply persistReducer to the combined root reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer,
});

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };
