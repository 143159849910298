import React, { useState } from "react";
import { Typography, Box, Button, Dialog } from "@mui/material";
import "./TrailExpiry.css";
import gillgall from "../../../assets/Gillgall.png";
import { useNavigate } from "react-router-dom";
import {
  CurrentPlanName,
  CurrentFrequency,
  changePaymentFrequency,
  changePlanName,
} from "../../../redux/profileDataSlice";
import { useDispatch, useSelector } from "react-redux";
const TrailExpiry = ({ openDialog, onContinuewithbasicBtnClick }) => {
  const dispatch = useDispatch();
  const CurrentFreq = useSelector(CurrentFrequency);
  const modifyPlane = useSelector(CurrentPlanName);
  const navigate = useNavigate();
  const viewplan = () => {
    dispatch(changePaymentFrequency(""));
    dispatch(changePlanName(""));
    navigate("/neetsubscriptionplans");
  };
  return (
    <Box>
      <Dialog
        open={openDialog}
        maxWidth='md'
        PaperProps={{
          style: {
            height: "45%",
            maxHeight: "100%",
            maxWidth: "100%",
            overflowX: "hidden",
          },
        }}
      >
        <div>
          <div
            style={{
              width: "750px",
              display: "flex",
              justifyContent: "center",
              marginBottom: "40px",
              marginTop: "10px",
            }}
          >
            <img
              src={gillgall}
              style={{
                height: "80px",
                display: "flex",
                justifyContent: "center",
              }}
            />
          </div>
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "26px",
              fontWeight: "600",
              marginBottom: "40px",
            }}
          >
            Your 30-day free trial has expired.
          </Typography>
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "20px",
              marginBottom: "40px",
            }}
          >
            To continue accessing all features, please upgrade your plan.
          </Typography>
          <Box className='but'>
            <Button variant='outlined' onClick={onContinuewithbasicBtnClick}>
              CONTINUE WITH BASIC
            </Button>
            <Button variant='contained' onClick={viewplan}>
              VIEW PLANS & PRICING
            </Button>
          </Box>
        </div>
      </Dialog>
    </Box>
  );
};

export default TrailExpiry;
