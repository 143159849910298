import { useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { StyledButton, BackgroundBox } from "./notfoundstyle";

const NotFound = () => {
  const navigate = useNavigate();

  const GoHome = () => {
    navigate("/");
  };

  return (
    <BackgroundBox>
      <Container maxWidth='md' sx={{ textAlign: "center", paddingTop: "45%" }}>
        <StyledButton variant='contained' color='primary' size='large' onClick={GoHome}>
          Go to Home
        </StyledButton>
      </Container>
    </BackgroundBox>
  );
};

export default NotFound;
