import React from "react";
import { Box, IconButton, Typography, TextField } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Colors from "../../utils/Colors";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useTranslation } from "react-i18next";
const HeaderBox = ({
  bgcolor,
  title,
  backBtn,
  className,
  onBackBtn,
  searchNeeded,
  searchValue,
  setSearchValue,
}) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (onBackBtn) {
      onBackBtn();
    } else {
      navigate(-1);
    }
  };
  const suffixes = {
    1: "st",
    2: "nd",
    3: "rd",
  };

  const getClassSuffix = (className) => {
    const suffix = suffixes[className] || "th";
    return `${className}${suffix}`;
  };
  const findTopicks = () => {};
  const classNameSufix = getClassSuffix(className);
  const { t } = useTranslation(["translationHeaderBox"]);
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: bgcolor || Colors.globalColorThemeColor,
          borderRadius: "10px 10px 90px 10px",
          display: "flex",
          gap: "10px",
          padding: "10px 30px 10px 10px",
          justifyContent: "space-between",
        }}
      >
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          {backBtn && (
            <IconButton onClick={handleBackClick} sx={{ p: 0, m: 0 }}>
              <ArrowBack sx={{ color: "#fff" }} />
            </IconButton>
          )}
          <Typography
            sx={{
              fontSize: {
                xs: "10px",
                sm: "15px",
                md: "15px",
                lg: "18px",
                xl: "18px",
              },
              color: "#fff",
              fontWeight: 600,
            }}
          >
            {className && classNameSufix}&nbsp;
            {title}
          </Typography>
        </Box>
        {searchNeeded && (
          <Box>
            <input
              placeholder={t("Search ...")}
              style={{
                borderRadius: "10px",
                height: "30px",
                width: "100%",
              }}
              onChange={(event) => setSearchValue(event.target.value)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HeaderBox;
