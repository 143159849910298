import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import "./NeetCategories.css";
// import image from "../../assets/NeetCategoriesImages/image.png";
// import image2 from "../../assets/NeetCategoriesImages/image2.png";
// import imgFirst from "../../assets/NeetCategoriesImages/imgFirst.png";
// import imgSecond from "../../assets/NeetCategoriesImages/imgSecond.png";
// import liveImage from "../../assets/liveclass.png";
// import downloadImg from "../../assets/preupload.png";
import { useLocation, useNavigate } from "react-router-dom";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import { itemListEM } from "../Subjectpage/subjectsCardsImage";
import Colors from "../../utils/Colors";
import HistoryIcon from "@mui/icons-material/History";
import QuizIcon from "@mui/icons-material/Quiz";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import HeaderBox from "../../common/components/HeaderBox";
import { useTranslation } from "react-i18next";
export default function NEETCategoriesPage() {
  const { t } = useTranslation(["translationNEETCategoriesPage"]);
  const navigate = useNavigate();
  const location = useLocation();
  const neetSelectSubject = location.state.subjectname;
  const subjectData = itemListEM.find((item) => item.title === neetSelectSubject);
  const subjectColor = subjectData ? subjectData.color : Colors.globalColorThemeColor;

  const movingPreviousYearQuestion = (QuestionType) => {
    const navigatePath =
      QuestionType === "Previous Year Questions" ? "/prevYearQuestions" : "/mockExamQuestions";
    navigate(navigatePath, {
      state: {
        questionType: QuestionType,
        subject: neetSelectSubject,
        subjectColor: subjectColor,
      },
    });
  };
  // const backToHome = () => {
  //   navigate("/Home");
  // };
  const LiveClass = () => {
    navigate("/liveclasspage", {
      state: {
        subject: neetSelectSubject,
        subjectColor: subjectColor,
      },
    });
  };
  const preuploadclasses = () => {
    navigate("/preuploadclasses", {
      state: {
        subject: neetSelectSubject,
        subjectColor: subjectColor,
      },
    });
  };
  const categoryData = [
    {
      title: t("Previous Year Question Papers"),
      onClick: () => movingPreviousYearQuestion("Previous Year Questions"),
      imgSrc: <HistoryIcon sx={{ width: "70px", height: "70px", color: subjectColor }} />,
    },
    {
      title: t("Mock Exam Questions"),
      onClick: () => movingPreviousYearQuestion("Mock Exam Questions"),
      imgSrc: <QuizIcon sx={{ width: "70px", height: "70px", color: subjectColor }} />,
    },
    {
      title: t("Live Classes"),
      onClick: LiveClass,
      imgSrc: <LiveTvIcon sx={{ width: "70px", height: "70px", color: subjectColor }} />,
    },
    {
      title: t("Pre Upload Classes"),
      onClick: preuploadclasses,
      imgSrc: <UploadFileIcon sx={{ width: "70px", height: "70px", color: subjectColor }} />,
    },
  ];
  return (
    <>
      <SubjectNavbar title={t("NEET Categories")} />
      <Box sx={{ padding: "20px 30px" }}>
        <HeaderBox bgcolor={subjectColor} title={neetSelectSubject} backBtn={true} />

        <Grid container spacing={5} mt={1}>
          {categoryData.map((card, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "200px",
                  gap: "10px",
                  borderRadius: "10px",
                  cursor: "pointer",
                  "&:hover": {
                    outline: `2px solid ${subjectColor}`,
                  },
                }}
                elevation={7}
                onClick={card.onClick}
              >
                <Box>{card.imgSrc}</Box>
                {/* <img
                  src={card.imgSrc}
                  className='card-img'
                  alt=''
                  style={{ height: index >= 2 ? "60px" : "auto" }}
                /> */}
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  {card.title}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}
