import React, { useEffect, useState } from "react";
import { Card, Stack, Typography, Button, Box, Grid, Pagination } from "@mui/material";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { APIClient } from "../../utils/APIClient";
import Swal from "sweetalert2";
import Colors from "../../utils/Colors";
import CircularProgress from "@mui/material/CircularProgress";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import HeaderBox from "../../common/components/HeaderBox";
import LockIcon from "@mui/icons-material/Lock";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { CurrentBoard, CurrentClassName } from "../../redux/profileDataSlice";
const PrevYearQuestionsPage = () => {
  const { t } = useTranslation(["translationPrevYearQuestionsPage"]);
  const navigate = useNavigate();
  const location = useLocation();
  const previousyearquestions = location?.state?.questionType || "";
  const selectSubject = location.state.subject;
  const subjectColor = location.state.subjectColor;
  const className = useSelector(CurrentClassName);
  const board = useSelector(CurrentBoard);
  const [previousYearQuestion, setPreviousYearQuestion] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);
  const getAllTest = () => {
    const token = localStorage.getItem("token");
    const payload = {
      questionType: previousyearquestions,
      page: 0,
      itemsPerPage: 0,
      subject: selectSubject,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/getAllTest`, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        const receivedData = response.data.data;
        setPreviousYearQuestion(response.data.data);

        if (selectSubject && receivedData.length > 0) {
          // const filteredQuestions = receivedData.filter((item) => item.subject === selectSubject);
          setPreviousYearQuestion(receivedData);
        }
        setPageLoading(false);
      })
      .catch((error) => {
        setPageLoading(false);
        console.log(error);
      });
  };
  const changeCPage = (id) => {
    setCurrentPage(id);
  };
  // <--- Pagination --->
  const recordsPerPage = 9;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = previousYearQuestion.slice(firstIndex, lastIndex);
  const npage = Math.ceil(previousYearQuestion.length / recordsPerPage);
  useEffect(() => {
    getAllTest();
  }, []);

  const goToViewQuestion = (item) => {
    if (item.questionsCount === 0) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: Colors.globalColorThemeColor,
        title: "No Question Available",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    } else {
      navigate("/questionDetailPage", {
        state: {
          questionType: previousyearquestions,
          year: item.year,
          subject: selectSubject,
          subjectColor,
        },
      });
    }
  };
  const PreviouesYearTestClick = (item) => {
    if (item.questionsCount === 0) {
      Swal.fire({
        icon: "error",
        confirmButtonColor: Colors.globalColorThemeColor,
        title: "No Question Available",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      });
    } else {
      const payload = {
        questionType: item.questionType,
        subject: item.subject,
        year: item.year,
        page: 0,
        itemCount: 0,
      };
      axios.post(`${APIClient().API_BASE_URL}/api/getQuestionSet`, payload).then((response) => {
        navigate("/quizPage", {
          state: {
            questionSet: response.data.data.questionSet,
            questionNames: item.questionType,
            year: item.year,
            hideChangeboard: true,
            questionSetMainID: response.data.data.id,
          },
        });
      });
    }
  };
  const onUnlockBtnClick = () => {
    navigate("/neetsubscriptionplans");
  };
  return (
    <div>
      <SubjectNavbar />
      <Box sx={{ padding: "20px 30px" }}>
        <HeaderBox bgcolor={subjectColor} title={t("Previous Year Questions")} backBtn={true} />
        <Box>
          {pageLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              {" "}
              <CircularProgress />
            </div>
          ) : (
            <Grid container spacing={4} mt={1}>
              {previousYearQuestion.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "100%",
                  }}
                >
                  <Typography variant='h1' sx={{ fontSize: "22px" }}>
                    {t("No Previous Year exams available for this subject.")}
                  </Typography>
                </div>
              ) : (
                records.map((item) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={item.id}>
                      <Card
                        elevation={5}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "15px",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "15px",
                              textAlign: "center",
                              fontWeight: "600",
                            }}
                          >
                            {`${item.year} ${item.questionType}`}
                          </Typography>
                          <Stack direction={"row"} justifyContent={"space-between"} mt={2}>
                            <Stack direction={"column"} alignItems={"flex-start"}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  "@media (max-width:425px)": {
                                    fontSize: "11px",
                                  },
                                }}
                              >
                                {t("Year")}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  "@media (max-width:425px)": {
                                    fontSize: "11px",
                                  },
                                }}
                              >
                                {item.year}
                              </Typography>
                            </Stack>
                            <Stack direction={"column"} alignItems={"flex-end"}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  "@media (max-width:425px)": {
                                    fontSize: "11px",
                                  },
                                }}
                              >
                                {t("Questions")}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  "@media (max-width:425px)": {
                                    fontSize: "11px",
                                  },
                                }}
                              >
                                {item.questionsCount}
                              </Typography>
                            </Stack>
                          </Stack>
                          <Stack direction={"row"} justifyContent={"space-between"} mt={2}>
                            <Stack direction={"column"} alignItems={"flex-start"}>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  "@media (max-width:425px)": {
                                    fontSize: "11px",
                                  },
                                }}
                              >
                                {t("Highest Marks")}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  "@media (max-width:425px)": {
                                    fontSize: "11px",
                                  },
                                }}
                              >
                                {item.highestMark || t("Not Attended Yet")}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Box>
                        {item.isLocked === false ? (
                          <Stack
                            direction={"row"}
                            gap={2}
                            sx={{ width: "100%", marginBottom: "10px", marginTop: "30px" }}
                          >
                            <Button
                              variant='outlined'
                              sx={{
                                color: subjectColor,
                                height: "40px",
                                width: "100%",
                                fontSize: "13px",
                                fontWeight: "bold",
                                textTransform: "none",
                                "@media (max-width:425px)": {
                                  fontSize: "11px",
                                },
                              }}
                              onClick={() => goToViewQuestion(item)}
                            >
                              {t("View Questions")}
                            </Button>
                            <Button
                              variant='outlined'
                              sx={{
                                color: "#fff",
                                height: "40px",
                                width: "100%",
                                backgroundColor: subjectColor,
                                fontSize: "13px",
                                fontWeight: "bold",
                                textTransform: "none",
                                "&:hover": {
                                  backgroundColor: subjectColor,
                                  fontSize: "13px",
                                  fontWeight: "bold",
                                  textTransform: "none",
                                },
                                "@media (max-width:425px)": {
                                  fontSize: "11px",
                                },
                              }}
                              onClick={() => PreviouesYearTestClick(item)}
                            >
                              {t("Start Exam")}
                            </Button>
                          </Stack>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              paddingTop: "0px",
                            }}
                          >
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                              <LockIcon />
                            </Box>
                            <Button
                              variant='contained'
                              sx={{
                                backgroundColor: "#DAA520",
                                fontSize: "15px",
                                marginTop: "15px",
                                "@media (max-width:425px)": {
                                  fontSize: "11px",
                                },
                              }}
                              onClick={onUnlockBtnClick}
                            >
                              {t("unlock")}
                            </Button>
                          </Box>
                        )}
                      </Card>
                    </Grid>
                  );
                })
              )}
            </Grid>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Pagination count={npage} page={currentPage} onChange={(e, i) => changeCPage(i)} />
      </Box>
    </div>
  );
};
export default PrevYearQuestionsPage;
