import { createSlice } from "@reduxjs/toolkit";

const unitsDataSlice = createSlice({
  name: "unitsData",
  initialState: {
    unitsData: [],
  },
  reducers: {
    setUnitsData: (state, action) => {
      state.unitsData = action.payload;
    },
  },
});

export const { setUnitsData } = unitsDataSlice.actions;

export const CurrentUnitsData = (state) => state.unitsData.unitsData;

export default unitsDataSlice.reducer;
