import React from "react";
import "./CongratsPage.css";
import { AppBar, Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import awardImage from "../../assets/CongratsImages/awardImage.png";
import { useNavigate } from "react-router-dom";

export default function CongratsPage() {
  const navigate = useNavigate();

  const GoToHomePage = () => {
    navigate("/home");
  };

  return (
    <>
      <div className='container'>
        <AppBar className='appbar-congrats' style={{ backgroundColor: "#35affd" }}>
          <ArrowBackIcon className='arrow-appbar' />
        </AppBar>
        <div className='congrats-container'>
          <div className='congrats-section'>
            <div className='image-section'>
              <img src={awardImage} alt='' className='congrats-image' />
            </div>
            <div className='text-section'>
              <Typography variant='h3' className='cong-head'>
                Congrats
              </Typography>
              <Typography variant='p' className='cong-para'>
                Now you are prepared to take your permit test
              </Typography>
            </div>
            <div className='congrats-btn'>
              <Button variant='outlined' className='cong-btn' onClick={GoToHomePage}>
                Great
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
