import Landingpage from "./Landingpage/Landingpage";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Onboarding from "./pages/Onboardingpage";
import SelectBoard from "./pages/SelectBoard";
import Register from "./pages/Register";
import Choosetheclass from "./pages/Choosetheclass";
import Login from "./pages/Login";
import OTPPage from "./pages/OTPPage/OTPPage";
import Units from "./pages/Units/index";
import NeetSubscription from "./pages/Subscription/NeetSubscription";
import StateBoardSubscriptionPlans from "./pages/Subscription/components/StateBoardSubscriptionPlans/StateBoardSubscriptionPlans";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Bookmarks from "./pages/Bookmarks";
import AppFeatures from "./pages/AppFeatures";
import Subjectpage from "./pages/Subjectpage/SubjectPage";
import GillgallDeletion from "./pages/GillgallDeletion/GillgallDeletion";
import MockExamQuestionsPage from "./pages/MockExamQuestionsPage/MockExamQuestions";
import NEETCategoriesPage from "./pages/NEETCategoriesPage/NeetCategories";
import CongratsPage from "./pages/CongratsPage/CongratsPage";
import NeetExamQuizPage from "./pages/NeetExamQuizPage/NeetExamQuizPage";
import PrevYearQuestionsPage from "./pages/PrevYearQuestionsPage/PrevYearQuestionsPage";
import PrevQuestionsPDFPage from "./pages/PrevQuestionsPDFPage/PrevQuestionsPDFPage";
import NEETHOMEPAGE from "./pages/NEETHOMEPAGE/NEETHOMEPAGE";
import QuizPage from "./pages/QuizPage/QuizPage";
import QuestionDetailPage from "./pages/QuestionDetailPage/QuestionDetailPage";
import ExamResult from "./pages/ExamResult/ExamResult";
import LiveClassPage from "./pages/LiveClassPage/LiveClassPage";
import PreUploadClasses from "./pages/PreUploadClasses/PreUploadClasses";
//import "react-quill/dist/quill.snow.css";
import { Box } from "@mui/material";
import NeetSubscriptionPlans from "./pages/Subscription/components/NeetSubscriptionPlans/NeetSubscriptionPlans";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import CBSESubscriptionPlans from "./pages/Subscription/components/CBSESubscriptionPlans/CBSESubscriptionPlans";
import NotFound from "./pages/Notfoundpage/notfoundpage";

const ProtectedRoute = ({ component: Component }) => {
  const token = localStorage.getItem("token");
  return token ? <Component /> : <Navigate to='/' />;
};
function App() {
  return (
    <Box>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Landingpage />} />
          <Route path='/onboarding' element={<Onboarding />} />
          <Route path='/selectboard' element={<SelectBoard />} />
          <Route path='/chooseclass' element={<Choosetheclass />} />
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/otp' element={<OTPPage />} />
          <Route path='/aboutus' element={<AboutUs />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path='/appfeature' element={<AppFeatures />} />
          <Route path='/neetsubscriptionplans' element={<NeetSubscriptionPlans />} />
          <Route path='/gillgallDeletion' element={<GillgallDeletion />} />
          <Route path='/neetsubscribe' element={<NeetSubscription />} />

          <Route path='/home' element={<ProtectedRoute component={Subjectpage} />} />
          <Route path='/units' element={<ProtectedRoute component={Units} />} />
          <Route path='/bookmarks' element={<ProtectedRoute component={Bookmarks} />} />
          <Route
            path='/mockExamQuestions'
            element={<ProtectedRoute component={MockExamQuestionsPage} />}
          />
          <Route
            path='/neetCategories'
            element={<ProtectedRoute component={NEETCategoriesPage} />}
          />
          <Route path='/congrats' element={<ProtectedRoute component={CongratsPage} />} />
          <Route path='/neetExamPage' element={<ProtectedRoute component={NeetExamQuizPage} />} />
          <Route
            path='/prevYearQuestions'
            element={<ProtectedRoute component={PrevYearQuestionsPage} />}
          />
          <Route
            path='/prevQuestionsPDF'
            element={<ProtectedRoute component={PrevQuestionsPDFPage} />}
          />
          <Route path='/NEETHome' element={<ProtectedRoute component={NEETHOMEPAGE} />} />
          <Route path='/quizPage' element={<ProtectedRoute component={QuizPage} />} />
          <Route
            path='/questionDetailPage'
            element={<ProtectedRoute component={QuestionDetailPage} />}
          />
          <Route path='/congratapage' element={<ProtectedRoute component={CongratsPage} />} />
          <Route path='/examresult' element={<ProtectedRoute component={ExamResult} />} />
          <Route path='/liveclasspage' element={<ProtectedRoute component={LiveClassPage} />} />
          <Route
            path='/preuploadclasses'
            element={<ProtectedRoute component={PreUploadClasses} />}
          />
          <Route
            path='/stateboardsubscription'
            element={<ProtectedRoute component={StateBoardSubscriptionPlans} />}
          />
          <Route
            path='/cbsesubscriptions'
            element={<ProtectedRoute component={CBSESubscriptionPlans} />}
          />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Box>
  );
}
export default App;
