import { AppBar, Stack, Toolbar, Typography, useTheme } from "@mui/material";
import React from "react";
import WestIcon from "@mui/icons-material/West";

const PrevQuestionsPDFPage = () => {
  const theme = useTheme();

  return (
    <>
      <AppBar sx={{ background: "#21A6FD", height: "90px" }}>
        <Toolbar>
          <WestIcon sx={{ color: "#FFFFFF", marginTop: "20px", fontSize: "30px" }} />
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            sx={{ flexGrow: 1, marginTop: "20px" }}
          >
            <Typography
              variant='h6'
              component='div'
              sx={{ color: "#FFFFFF", font: "hind", fontSize: "25px", fontWeight: 300 }}
            >
              NEET
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      <Typography
        variant='h4'
        color={"#000000"}
        sx={{ fontSize: "40px", fontWeight: "bold", textAlign: "center", marginTop: "120px" }}
      >
        Subject:Physics
      </Typography>
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{ marginTop: "20px" }}
      >
        <Typography
          variant='h5'
          sx={{ font: "hind", fontSize: "24px", fontWeight: 540, width: "1100px" }}
        >
          1. Which of the following organelles is responsible for producing ATP through cellular
          respiration in eukaryotic cells?
        </Typography>
        <ol
          style={{
            listStyleType: "upper-alpha",
            marginRight: "300px",
            width: "800px",
            marginTop: "10px",
          }}
        >
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            {" "}
            Nucleus
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Ribosome
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Mitochondria
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Golgi apparatus
          </li>
        </ol>
      </Stack>

      <Typography
        sx={{
          font: "hind",
          fontSize: "14px",
          color: "text.secondary",
          fontWeight: 540,
          marginLeft: "1400px",
          marginTop: "-70px",
        }}
      >
        Answer :
      </Typography>
      <Typography
        variant='h3'
        sx={{
          font: "hind",
          fontSize: "20px",
          color: "#000000",
          marginLeft: "1310px",
          marginTop: "10px",
        }}
      >
        C) Mitochondria
      </Typography>
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{ marginTop: "20px" }}
      >
        <Typography
          variant='h5'
          sx={{ font: "hind", fontSize: "24px", fontWeight: 540, width: "1100px" }}
        >
          2. Which of the following organelles is NOT a characteristic of prokaryotic cells?
        </Typography>
        <ol
          style={{
            listStyleType: "upper-alpha",
            marginRight: "300px",
            width: "800px",
            marginTop: "10px",
          }}
        >
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Presence of a Nucleus
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Lack of membrane-bound organelles
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Presence of ribosomes
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Circular DNA
          </li>
        </ol>
      </Stack>

      <Typography
        sx={{
          font: "hind",
          fontSize: "14px",
          color: "text.secondary",
          fontWeight: 540,
          marginLeft: "1400px",
          marginTop: "-70px",
        }}
      >
        Answer :
      </Typography>
      <Typography
        variant='h3'
        sx={{
          font: "hind",
          fontSize: "20px",
          color: "#000000",
          marginLeft: "1245px",
          marginTop: "10px",
        }}
      >
        A) Presence of nucleus
      </Typography>
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{ marginTop: "20px" }}
      >
        <Typography
          variant='h5'
          sx={{ font: "hind", fontSize: "24px", fontWeight: 540, width: "1100px" }}
        >
          3. Which of the following is the primary function of the stomata in plants?
        </Typography>
        <ol
          style={{
            listStyleType: "upper-alpha",
            marginRight: "300px",
            width: "800px",
            marginTop: "10px",
          }}
        >
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Photosynthesis
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Transpiration
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Respiration
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Reproduction
          </li>
        </ol>
      </Stack>

      <Typography
        sx={{
          font: "hind",
          fontSize: "14px",
          color: "text.secondary",
          fontWeight: 540,
          marginLeft: "1400px",
          marginTop: "-70px",
        }}
      >
        Answer :
      </Typography>
      <Typography
        variant='h3'
        sx={{
          font: "hind",
          fontSize: "20px",
          color: "#000000",
          marginLeft: "1310px",
          marginTop: "10px",
        }}
      >
        B) Transpiration
      </Typography>
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{ marginTop: "20px" }}
      >
        <Typography
          variant='h5'
          sx={{ font: "hind", fontSize: "24px", fontWeight: 540, width: "1100px" }}
        >
          4. Which of the following is NOT a component of the cell membrane?
        </Typography>
        <ol
          style={{
            listStyleType: "upper-alpha",
            marginRight: "300px",
            width: "800px",
            marginTop: "10px",
          }}
        >
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Phospholipids
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Proteins
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Nucleic acids
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Cholesterol
          </li>
        </ol>
      </Stack>

      <Typography
        sx={{
          font: "hind",
          fontSize: "14px",
          color: "text.secondary",
          fontWeight: 540,
          marginLeft: "1400px",
          marginTop: "-70px",
        }}
      >
        Answer :
      </Typography>
      <Typography
        variant='h3'
        sx={{
          font: "hind",
          fontSize: "20px",
          color: "#000000",
          marginLeft: "1308px",
          marginTop: "10px",
        }}
      >
        C) Nucleic acids
      </Typography>
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{ marginTop: "20px" }}
      >
        <Typography
          variant='h5'
          sx={{ font: "hind", fontSize: "24px", fontWeight: 540, width: "1100px" }}
        >
          5. Which of the following is responsible for the breakdown of cellular waste and debris in
          eukaryotic cells?
        </Typography>
        <ol
          style={{
            listStyleType: "upper-alpha",
            marginRight: "300px",
            width: "800px",
            marginTop: "10px",
          }}
        >
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Ribosome
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Golgi apparatus
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Lysosome
          </li>
          <li
            style={{
              color: theme.palette.text.secondary,
              font: "hind",
              fontWeight: 500,
              fontSize: "15px",
            }}
          >
            Endoplasmic reticulam
          </li>
        </ol>
      </Stack>

      <Typography
        sx={{
          font: "hind",
          fontSize: "14px",
          color: "text.secondary",
          fontWeight: 540,
          marginLeft: "1400px",
          marginTop: "-70px",
        }}
      >
        Answer :
      </Typography>
      <Typography
        variant='h3'
        sx={{
          font: "hind",
          fontSize: "20px",
          color: "#000000",
          marginLeft: "1335px",
          marginTop: "10px",
        }}
      >
        C) Lysosome
      </Typography>
    </>
  );
};

export default PrevQuestionsPDFPage;
