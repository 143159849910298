import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./Landingpage/translation/en/translationEN.json";
import translationTA from "./Landingpage/translation/ta/translationTA.json";
import translationOnboardingEN from "./pages/Onboardingpage/translationOnboarding/en/translationOnboardingEN.json";
import translationOnboardingTA from "./pages/Onboardingpage/translationOnboarding/ta/translationOnboardingTA.json";
import translationNavbarEN from "./Navbar/translationNavbar/en/translationNavabrEN.json";
import transalationNavbarTA from "./Navbar/translationNavbar/ta/translationNavbarTA.json";
import translationSelectBoardEN from "./pages/SelectBoard/translationSelectBoard/en/translationSelectBoardEN.json";
import translationSelectBoardTA from "./pages/SelectBoard/translationSelectBoard/ta/translationSelectBoardTA.json";
import translationChoosetheclassEN from "./pages/Choosetheclass/translationChoosetheclass/en/translationChoosetheclassEN.json";
import translationChoosetheclassTA from "./pages/Choosetheclass/translationChoosetheclass/ta/translationChoosetheclassTA.json";
import translationRegisterEN from "./pages/Register/translationRegister/en/translationRegisterEN.json";
import translationRegisterTA from "./pages/Register/translationRegister/ta/transaltionRegisterTA.json";
import translationLoginEN from "./pages/Login/translationLogin/en/translationEN.json";
import translationLoginTA from "./pages/Login/translationLogin/ta/translationTA.json";
import translationTermsandconditionEN from "./common/Termsandcondition/translationTermsandcondition/en/translationTermsandconditionEN.json";
import translationTermsandconditionTA from "./common/Termsandcondition/translationTermsandcondition/ta/translationTermsandconditionTA.json";
import translationOTPPageEN from "./pages/OTPPage/translationOTPPage/en/translationOTPPageEN.json";
import translationOTPPageTA from "./pages/OTPPage/translationOTPPage/ta/translationOTPPageTA.json";
import translationSubjectNavbarEN from "./pages/SubjectNavbar/translationSubjectNavbar/en/translationSubjectNavbarEN.json";
import translationSubjectNavbarTA from "./pages/SubjectNavbar/translationSubjectNavbar/ta/translationSubjectNavbarTA.json";
import translationNeetSubscriptionEN from "./pages/Subscription/translationNeetSubscription/en/translationNeetSubscriptionEN.json";
import translationNeetSubscriptionTA from "./pages/Subscription/translationNeetSubscription/ta/translationNeetSubscriptionTA.json";
import translationSubjectpageEN from "./pages/Subjectpage/translationSubjectpage/en/translationSubjectpageEN.json";
import translationSubjectpageTA from "./pages/Subjectpage/translationSubjectpage/ta/translationSubjectpageTA.json";
import translationNEETCategoriesPageEN from "./pages/NEETCategoriesPage/translationNEETCategoriesPage/en/translationNEETCategoriesPageEN.json";
import translationNEETCategoriesPageTA from "./pages/NEETCategoriesPage/translationNEETCategoriesPage/ta/translationNEETCategoriesPageTA.json";
import translationPrevYearQuestionsPageEN from "./pages/PrevYearQuestionsPage/translationPrevYearQuestionsPage/en/translationPrevYearQuestionsPageEN.json";
import translationPrevYearQuestionsPageTA from "./pages/PrevYearQuestionsPage/translationPrevYearQuestionsPage/ta/translationPrevYearQuestionsPageTA.json";
import translationMockExamQuestionsPageEN from "./pages/MockExamQuestionsPage/translationMockExamQuestionsPage/en/translationMockExamQuestionsPageEN.json";
import translationMockExamQuestionsPageTA from "./pages/MockExamQuestionsPage/translationMockExamQuestionsPage/ta/translationMockExamQuestionsPageTA.json";
import translationAboutUsEN from "./pages/AboutUs/translationAboutUs/en/translationAboutUsEN.json";
import translationAboutUsTA from "./pages/AboutUs/translationAboutUs/ta/translationAboutUsTA.json";
import translationNeetSubscriptionPlansEN from "./pages/Subscription/components/NeetSubscriptionPlans/translationNeetSubscriptionPlans/en/translationNeetSubscriptionPlansEN.json";
import translationNeetSubscriptionPlansTA from "./pages/Subscription/components/NeetSubscriptionPlans/translationNeetSubscriptionPlans/ta/translationNeetSubscriptionPlansTA.json";
import translationQuizPageEN from "./pages/QuizPage/translationQuizPage/en/translationQuizPageEN.json";
import translationQuizPageTA from "./pages/QuizPage/translationQuizPage/ta/translationQuizPageTA.json";
import translationLiveClassPageEN from "./pages/LiveClassPage/translationLiveClassPage/en/translationLiveClassPageEN.json";
import translationLiveClassPageTA from "./pages/LiveClassPage/translationLiveClassPage/ta/translationLiveClassPageTA.json";
import translationPreUploadClassesEN from "./pages/PreUploadClasses/translationPreUploadClasses/en/translationPreUploadClassesEN.json";
import translationPreUploadClassesTA from "./pages/PreUploadClasses/translationPreUploadClasses/ta/translationPreUploadClassesTA.json";
import translationExamResultEN from "./pages/ExamResult/translationExamResult/en/translationExamResultEN.json";
import translationExamResultTA from "./pages/ExamResult/translationExamResult/ta/translationExamResultTA.json";
import translationUnitsEN from "./pages/Units/translationUnits/en/translationUnitsEN.json";
import translationUnitsTA from "./pages/Units/translationUnits/ta/translationUnitsTA.json";
import translationUnitsQandAEN from "./pages/Units/components/translationUnitsQandA/en/translationUnitsQandAEN.json";
import translationUnitsQandATA from "./pages/Units/components/translationUnitsQandA/ta/translationUnitsQandATA.json";
import translationStateBoardSubscriptionplansEN from "./pages/Subscription/components/StateBoardSubscriptionPlans/translationStateBoardSubscriptionplans/en/translationStateBoardSubscriptionplansEN.json";
import translationStateBoardSubscriptionplansTA from "./pages/Subscription/components/StateBoardSubscriptionPlans/translationStateBoardSubscriptionplans/ta/translationStateBoardSubscriptionplansTA.json";
import translationUnitsQuizeEN from "./pages/Units/components/translationUnitsQuize/en/translationUnitsQuizeEN.json";
import translationUnitsQuizeTA from "./pages/Units/components/translationUnitsQuize/ta/translationUnitsQuizeTA.json";
import translationLiveclassesEN from "./pages/Subjectpage/Liveclasses/translationLiveclasses/en/translationLiveclassesEN.json";
import translationLiveclassesTA from "./pages/Subjectpage/Liveclasses/translationLiveclasses/ta/translationLiveclassesTA.json";
import translationPreuploadClassEN from "./pages/Subjectpage/PreuploadClass/translationPreuploadClass/en/translationPreuploadClassEN.json";
import translationPreuploadClassTA from "./pages/Subjectpage/PreuploadClass/translationPreuploadClass/ta/translationPreuploadClassTA.json";
import translationCBSESubscriptionPlanEN from "./pages/Subscription/components/CBSESubscriptionPlans/translationCBSESubscriptionPlans/en/translationCBSESubscriptionPlansEN.json";
import translationCBSESubscriptionPlanTA from "./pages/Subscription/components/CBSESubscriptionPlans/translationCBSESubscriptionPlans/ta/translationCBSESubscriptionPlansTA.json";
import translationAppFeaturesEN from "./pages/AppFeatures/translationAppFeatures/en/translationAppFeaturesEN.json";
import translationAppFeaturesTA from "./pages/AppFeatures/translationAppFeatures/ta/translationAppFeaturesTA.json";
import translationContactUSEN from "./pages/ContactUs/translationContactUS/en/translationContactUSEN.json";
import translationContactUSTA from "./pages/ContactUs/translationContactUS/ta/translationContactUSTA.json";
import translationUnitsubvideosEN from "./pages/Units/components/translationUnitsubvideos/en/translationUnitsubvideosEN.json";
import translationUnitsubvideosTA from "./pages/Units/components/translationUnitsubvideos/ta/translationUnitsubvideosTA.json";
import translationHeaderBoxEN from "./common/components/translationHeaderBox/en/translationHeaderBoxEN.json";
import translationHeaderBoxTA from "./common/components/translationHeaderBox/ta/translationHeaderBoxTA.json";
import translationEasyMediumHardEN from "./pages/Units/translation EasyMediumHard/en/translationEasyMediumHardEN.json";
import translationEasyMediumHardTA from "./pages/Units/translation EasyMediumHard/ta/translationEasyMediumHardTA.json";
import translationBookmarksEN from "./pages/Bookmarks/translationBookmarks/en/translationBookmarksEN.json";
import translationBookmarksTA from "./pages/Bookmarks/translationBookmarks/ta/translationBookmarksTA.json";
import translationResultEN from "./pages/Units/components/translationResult/en/translationResultEN.json";
import translationResultTA from "./pages/Units/components/translationResult/ta/translationResultTA.json";
const resources = {
  en: {
    translation: translationEN,
    translationOnboarding: translationOnboardingEN,
    translationNavbar: translationNavbarEN,
    translationSelectBoard: translationSelectBoardEN,
    translationChoosetheclass: translationChoosetheclassEN,
    translationRegister: translationRegisterEN,
    translationLogin: translationLoginEN,
    translationTermsandcondition: translationTermsandconditionEN,
    translationOTPPage: translationOTPPageEN,
    translationSubjectNavbar: translationSubjectNavbarEN,
    translationNeetSubscription: translationNeetSubscriptionEN,
    translationSubjectpage: translationSubjectpageEN,
    translationNEETCategoriesPage: translationNEETCategoriesPageEN,
    translationPrevYearQuestionsPage: translationPrevYearQuestionsPageEN,
    translationMockExamQuestionsPage: translationMockExamQuestionsPageEN,
    translationAboutUs: translationAboutUsEN,
    translationNeetSubscriptionPlans: translationNeetSubscriptionPlansEN,
    translationQuizPage: translationQuizPageEN,
    translationLiveClassPage: translationLiveClassPageEN,
    translationPreUploadClasses: translationPreUploadClassesEN,
    translationExamResult: translationExamResultEN,
    translationUnits: translationUnitsEN,
    translationUnitsQandA: translationUnitsQandAEN,
    translationStateBoardSubscriptionplans: translationStateBoardSubscriptionplansEN,
    translationUnitsQuize: translationUnitsQuizeEN,
    transaltionLiveclasses: translationLiveclassesEN,
    translationPreuploadClass: translationPreuploadClassEN,
    translationCBSESubscriptionPlans: translationCBSESubscriptionPlanEN,
    translationAppFeatures: translationAppFeaturesEN,
    translationContactUS: translationContactUSEN,
    translationUnitsubvideos: translationUnitsubvideosEN,
    translationHeaderBox: translationHeaderBoxEN,
    translationEasyMediumHard: translationEasyMediumHardEN,
    translationBookmarks: translationBookmarksEN,
    translationResult: translationResultEN,
  },
  ta: {
    translation: translationTA,
    translationOnboarding: translationOnboardingTA,
    translationNavbar: transalationNavbarTA,
    translationSelectBoard: translationSelectBoardTA,
    translationChoosetheclass: translationChoosetheclassTA,
    translationRegister: translationRegisterTA,
    translationLogin: translationLoginTA,
    translationTermsandcondition: translationTermsandconditionTA,
    translationOTPPage: translationOTPPageTA,
    translationSubjectNavbar: translationSubjectNavbarTA,
    translationNeetSubscription: translationNeetSubscriptionTA,
    translationSubjectpage: translationSubjectpageTA,
    translationNEETCategoriesPage: translationNEETCategoriesPageTA,
    translationPrevYearQuestionsPage: translationPrevYearQuestionsPageTA,
    translationMockExamQuestionsPage: translationMockExamQuestionsPageTA,
    translationAboutUs: translationAboutUsTA,
    translationNeetSubscriptionPlans: translationNeetSubscriptionPlansTA,
    translationQuizPage: translationQuizPageTA,
    translationLiveClassPage: translationLiveClassPageTA,
    translationPreUploadClasses: translationPreUploadClassesTA,
    translationExamResult: translationExamResultTA,
    translationUnits: translationUnitsTA,
    translationUnitsQandA: translationUnitsQandATA,
    translationStateBoardSubscriptionplans: translationStateBoardSubscriptionplansTA,
    translationUnitsQuize: translationUnitsQuizeTA,
    translationLiveclasses: translationLiveclassesTA,
    translationPreuploadClass: translationPreuploadClassTA,
    translationCBSESubscriptionPlans: translationCBSESubscriptionPlanTA,
    translationAppFeatures: translationAppFeaturesTA,
    translationContactUS: translationContactUSTA,
    translationUnitsubvideos: translationUnitsubvideosTA,
    translationHeaderBox: translationHeaderBoxTA,
    translationEasyMediumHard: translationEasyMediumHardTA,
    translationBookmarks: translationBookmarksTA,
    translationResult: translationResultTA,
  },
};
const savedLanguage = localStorage.getItem("i18nextLng") || "en";
i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
