import { Box, Typography, styled, Pagination, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { APIClient } from "../../utils/APIClient";
import Swal from "sweetalert2";
import Colors from "../../utils/Colors";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import HeaderBox from "../../common/components/HeaderBox";
import "./QuestionDetailPage.css";
function QuestionDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const questionType = location?.state?.questionType || "";
  const year = location?.state?.year || "";
  const subjectname = location?.state?.subject || "";
  const mockExamName = location?.state?.mockExamName || "";
  const subjectColor = location.state.subjectColor;
  const page = location?.state?.page || "";
  const isEditPage = page === "edit";
  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numbersOfPages, setNumbersOfPages] = useState(0);
  const [showExplanations, setShowExplanations] = useState([]);

  const toggleExplanation = (index) => {
    setShowExplanations((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const recordsPerPage = 6;

  const OptionText = styled(Typography)(({ theme, isCorrect }) => ({
    fontSize: "14px",
    color: isCorrect ? theme.palette.success.main : "inherit",
  }));

  const changeCPage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let sendToTheBackendListData;
      if (questionType === "Previous Year Questions") {
        sendToTheBackendListData = {
          questionType: questionType,
          subject: subjectname,
          year: year,
          page: currentPage,
          itemCount: recordsPerPage,
        };
      } else if (questionType === "Mock Exam Questions") {
        sendToTheBackendListData = {
          questionType: questionType,
          subject: subjectname,
          mockExamName: mockExamName,
          page: currentPage,
          itemCount: recordsPerPage,
        };
      }
      try {
        const response = await axios.post(
          `${APIClient().API_BASE_URL}/api/getQuestionSet`,
          sendToTheBackendListData,
        );
        setData(response.data.data.questionSet);
        setNumbersOfPages(response.data.noOfPages);
        setCurrentPage(currentPage);
        setShowExplanations(new Array(response.data.data.questionSet.length).fill(false));
      } catch (error) {
        Swal.fire({
          icon: "error",
          confirmButtonColor: Colors.globalColorThemeColor,
          title: "No Question Available",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, questionType, subjectname, year]);

  const goToTheCategaryList = () => {
    navigate(-1);
  };

  return (
    <div>
      <SubjectNavbar />
      <Box sx={{ padding: "20px 30px" }}>
        <HeaderBox
          bgcolor={subjectColor}
          title={`${year} ${subjectname} ${questionType}`}
          backBtn={true}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "30px",
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              Data.map((question, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    border: `1px solid black`,
                    borderRadius: "7px",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <Box>
                    <ReactQuill
                      value={question.question}
                      readOnly={true}
                      modules={{ toolbar: false }}
                      className='ans'
                    />
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        flexWrap: "wrap",
                        textWrap: "wrap",
                      }}
                      className='options'
                    >
                      <OptionText>
                        A:
                        <ReactQuill
                          value={question.option1}
                          readOnly={true}
                          modules={{ toolbar: false }}
                          style={{
                            width: "200px",
                            backgroundColor:
                              question.correctOption === "A" ? "lightgreen" : "inherit",
                            borderRadius: "7px",
                          }}
                          className='text'
                        />
                      </OptionText>
                      <OptionText>
                        B:
                        <ReactQuill
                          value={question.option2}
                          readOnly={true}
                          modules={{ toolbar: false }}
                          style={{
                            width: "200px",
                            backgroundColor:
                              question.correctOption === "B" ? "lightgreen" : "inherit",
                            borderRadius: "7px",
                          }}
                        />
                      </OptionText>
                      <OptionText>
                        C:
                        <ReactQuill
                          value={question.option3}
                          readOnly={true}
                          modules={{ toolbar: false }}
                          style={{
                            width: "200px",
                            backgroundColor:
                              question.correctOption === "C" ? "lightgreen" : "inherit",
                            borderRadius: "7px",
                          }}
                        />
                      </OptionText>
                      <OptionText>
                        D:
                        <ReactQuill
                          value={question.option4}
                          readOnly={true}
                          modules={{ toolbar: false }}
                          style={{
                            width: "200px",
                            backgroundColor:
                              question.correctOption === "D" ? "lightgreen" : "inherit",
                            borderRadius: "7px",
                          }}
                        />
                      </OptionText>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", margin: "5px 0px 5px 0px" }}>
                      <Button
                        onClick={() => toggleExplanation(index)}
                        variant='outlined'
                        sx={{
                          border: "none",
                          textTransform: "lowercase",
                          "&:hover": {
                            border: "none",
                          },
                        }}
                      >
                        {showExplanations[index] ? "Hide Explanation" : "Show Explanation"}
                      </Button>
                    </Box>
                    {showExplanations[index] && (
                      <Box sx={{ width: "100%" }}>
                        <ReactQuill
                          value={question.explanation}
                          readOnly={true}
                          modules={{ toolbar: false }}
                          style={{
                            backgroundColor: "inherit",
                            boreder: "none",
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              ))
            )}
          </Box>
        </Box>
        <Pagination
          count={numbersOfPages}
          page={currentPage}
          onChange={(e, i) => changeCPage(i)}
          variant='outlined'
          shape='rounded'
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        />
      </Box>
    </div>
  );
}

export default QuestionDetailPage;
