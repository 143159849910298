import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  CardMedia,
  Box,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import LockIcon from "@mui/icons-material/Lock";
const LiveClassList = ({ data }) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const location = useLocation();
  const subjectColor = location?.state?.subjectColor;
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleJoinClick = () => {
    window.open(data.meetLink, "_blank");
  };
  const formattedStartTime = dayjs(data.startTime).format("DD-MM-YYYY hh:mm A");
  const formattedEndTime = dayjs(data.endTime).format("DD-MM-YYYY hh:mm A");
  const unlock = () => {
    navigate("/neetsubscriptionplans");
  };
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens
        marginTop: "10px",
        height: { xs: "auto", sm: "" }, // Auto height for small screens
        width: "100%",
        marginBottom: "10px",
        border: "1px solid #ccc",
        boxSizing: "border-box",
        borderRadius: "5px",
        "@media (min-width: 499px) and (max-width: 599px) ": {
          flexDirection: "row",
        },
      }}
    >
      <CardMedia
        component='img'
        sx={{
          width: { xs: "130px", sm: "120px" }, // Full width on small screens  xs,sm,md,lg,xl
          height: { xs: "130px", sm: "auto" }, // Auto height for small screens
          objectFit: "cover",
        }}
        image={data.topicImg}
        alt='placeholder'
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column" }, // Stack content vertically on small screens
          justifyContent: "space-between",
          alignItems: { xs: "center", sm: "flex-start" }, // Center-align text on small screens
          padding: "10px",
          width: {
            xs: "100%",
            sm: "90%",
            md: "100%",
            lg: "100%",
            xl: "100%",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginBottom: { xs: "10px", sm: "0" },
            // Add spacing on small screens
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", sm: "row" }, // Stack vertically on small screens
              alignItems: { xs: "center", sm: "flex-start" },
              // justifyContent: { xs: "space-between" },

              opacity: 0.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                fontWeight: "bold",
              }}
            >
              <Typography
                sx={{
                  marginRight: "10px",
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                }}
              >
                Title
              </Typography>
              <Typography
                sx={{
                  minWidth: "20vw",
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                }}
                variant='body3'
              >
                {data.title}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                fontWeight: "bold",

                marginLeft: {
                  xs: "10px",
                  sm: "0px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
              }}
            >
              <Typography
                sx={{
                  marginRight: "10px",
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                }}
              >
                Subject
              </Typography>
              <Typography
                sx={{
                  minWidth: "10vw",
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                }}
                variant='body3'
              >
                {data.subject}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{
                marginRight: "10px",
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              Description
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography
                sx={{
                  maxWidth: "65vw",
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                  lineHeight: "1.2",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  opacity: 0.5,
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {data.desc.length > 50 ? `${data.desc.slice(0, 50)}...` : data.desc}

                {data.desc.length > 50 && (
                  <Tooltip
                    title={data.desc}
                    placement='bottom-end'
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      component='span'
                      color={subjectColor}
                      sx={{
                        cursor: "pointer",
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      &nbsp;Read more
                    </Typography>
                  </Tooltip>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row" },
            opacity: 0.5,
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              fontWeight: "bold",
              textWrap: "wrap",
            }}
          >
            <Typography
              sx={{
                //marginRight: "10px",
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              Author
            </Typography>
            <Typography
              sx={{
                minWidth: "20vw",
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
                // backgroundColor: "red",
                textWrap: "wrap",
              }}
              variant='body3'
            >
              {data.author}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              fontWeight: "bold",
              marginLeft: {
                // xs: "blue",
                sm: "0px",
                md: "20px",
                lg: "20px",
                xl: "20px",
              },
            }}
          >
            <Typography
              sx={{
                // marginRight: "10px",
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              Start Time
            </Typography>
            <Typography
              sx={{
                minWidth: "10vw",
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
              variant='body3'
            >
              {/* {dayjs(items.startTime).format("DD/MM/YYYY hh:mm A")} */}
              {formattedStartTime}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              fontWeight: "bold",
              //marginLeft: "15px",
              marginLeft: {
                xs: "0px",
                sm: "0px",
                md: "15px",
                lg: "15px",
                xl: "15px",
              },
            }}
          >
            <Typography
              sx={{
                marginRight: "10px",
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              End Time
            </Typography>
            <Typography
              sx={{
                minWidth: "10vw",
                fontSize: {
                  xs: "12px",
                  sm: "12px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
              variant='body3'
            >
              {/* {dayjs(items.endTime).format("DD/MM/YYYY hh:mm A")} */}
              {formattedEndTime}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      {data.isLocked === false ? (
        <CardActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "10px",
            // marginLeft: "auto",
            // "@media (max-width: 1024px)": {
            //   marginTop: "10px",
            //   justifyContent: "center",
            // },
          }}
        >
          <Box
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection={"column"}
          >
            <Button
              variant='contained'
              size='small'
              onClick={handleJoinClick}
              // sx={{
              //   width: "100%",
              //   marginLeft: "8px",
              // }}
              sx={{
                width: { xs: "70%", sm: "auto" },
                "@media (max-width: 1024px)": {
                  fontSize: "10px",
                },
              }}
            >
              Join
            </Button>
          </Box>
        </CardActions>
      ) : (
        <CardActions
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            // gap: "10px",
            "@media (max-width: 1024px)": {
              //marginTop: "10px",
            },
          }}
        >
          <Box
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            flexDirection={"column"}
          >
            {/* <LockIcon
         sx={{
           "@media (max-width: 1024px)": {
             fontSize: "22px",
           },
         }}
       /> */}
            <Button
              variant='contained'
              size='small'
              onClick={unlock}
              sx={{
                // width: "100%",
                // marginLeft: "8px",
                backgroundColor: "#DAA520",
                width: { xs: "70%", sm: "auto" },
                // backgroundColor: colors.primary,
                "@media (max-width: 1024px)": {
                  fontSize: "9px",
                },
              }}
              startIcon={<LockIcon />}
            >
              Unlock
            </Button>
          </Box>
        </CardActions>
      )}
    </Card>
  );
};

export default LiveClassList;
