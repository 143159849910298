import { Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Navebar from "../../Navbar/Navebar";
import "./otpstyle.css";
import mobile from "../../Vector.png";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { APIClient } from "../../utils/APIClient";
import axios from "axios";
import { useDispatch } from "react-redux";
import Colors from "../../utils/Colors";
import jwtDecode from "jwt-decode";
import { tokenVerify } from "../../utils/Helpers";
import {
  changeBoard,
  changeMedium,
  changeClass,
  changeName,
  changeMobileNo,
  changeEmailId,
  CurrentName,
  CurrentMobileNo,
  CurrentEmailId,
  CurrentValue,
  CurrentBoard,
  CurrentMedium,
  CurrentClass,
} from "../../redux/registerDataSlice";
import { useSelector } from "react-redux";
import { CurrentValueForStateplanNamePlanData } from "../../redux/stateBoardSubscriptionDetailSlice";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

const OTPPage = ({ delayResend = "180" }) => {
  const { t } = useTranslation(["translationOTPPage"]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const registerData = useSelector(CurrentValue);
  const sbPlanData = useSelector(CurrentValueForStateplanNamePlanData);
  const mobileNo = useSelector(CurrentMobileNo);
  const node_env = process.env.REACT_APP_NODE_ENV;
  const { isNewUser, planName, otpData, mob_no, SbPlanPrice, sbyear, sbplanType, otpV2Data } =
    location.state;
  const [otp, setOtp] = useState("");
  const [otpStatus, setOtpStatus] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [otpSessionId, setOTPSessionId] = useState(otpV2Data.otpSessionId);
  const resendOTP = () => {
    var payload = {
      mobNo: registerData.mobileNo.replace("-", "").replace(/\s/g, ""),
    };
    setMinutes(0);
    setSeconds(30);
    axios
      .post(`${APIClient().API_BASE_URL}/api/sentSmsOTP`, payload)
      .then((res) => {
        if (res.data.success) {
          setOTPSessionId(res.data.otpSessionId);
        }
      })
      .catch((err) => {
        console.error(err); // Log error for debugging
        Swal.fire({
          icon: "error",
          confirmButtonColor: Colors.globalColorThemeColor,
          title: "Server Down Try Again Later",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then(() => {
          localStorage.clear();
          navigate("/");
          setLoading(false);
        });
      });
  };
  const otpVerification = () => {
    setLoading(true);
    if (otp === "") {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Please Enter Your OTP",
        confirmButtonColor: Colors.globalColorThemeColor,
      });
    } else {
      if (!otp) return;
      const verifyOTPPayload = {
        otpSessionId: otpSessionId,
        otpEnteredByUser: otp,
      };
      axios
        .post(`${APIClient().API_BASE_URL}/api/verifySmsOTP`, verifyOTPPayload)
        .then((res) => {
          if (res.data.success) {
            if (isNewUser) {
              let basicDetails = {
                name: registerData.name,
                mob_no: "+91" + registerData.mobileNo,
                email_id: registerData.emailID,
                mode: "desktop",
                planName: planName,
                board: registerData.board,
              };
              let payload;
              if (registerData.board === "neet") {
                payload = {
                  ...basicDetails,
                };
              } else if (registerData.board === "cb") {
                payload = {
                  ...basicDetails,
                  medium: registerData.medium,
                  className: registerData.class,
                };
              } else {
                payload = {
                  ...basicDetails,
                  medium: registerData.medium,
                  className: registerData.class,
                };
              }
              axios
                .post(`${APIClient().API_BASE_URL}/api/netSmart_register`, payload)
                .then((res) => {
                  const receivedToken = res.data.token;
                  localStorage.setItem("token", receivedToken);
                  navigate("/home");
                });
              dispatch(changeBoard(""));
              dispatch(changeMedium(""));
              dispatch(changeClass(""));
            } else {
              const loginDataToSent = {
                mobNo: location.state.mob_no,
                mode: "desktop",
              };
              axios
                .post(`${APIClient().API_BASE_URL}/api/netSmart_login_data`, loginDataToSent)
                .then((res) => {
                  const receivedToken = res.data.token;
                  localStorage.setItem("token", receivedToken);
                  navigate("/home");
                });
            }
          } else {
            enqueueSnackbar("Invalid OTP", { variant: "success" });
          }
        })
        .catch((err) => {
          console.error(err); // Log error for debugging
          if (err.response.data.message) {
            enqueueSnackbar(err.response.data.message, { variant: "error" });
            setLoading(false);
            return;
          }
          Swal.fire({
            icon: "error",
            confirmButtonColor: Colors.globalColorThemeColor,
            title: "Server Down Try Again Later",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            localStorage.clear();
            navigate("/");
            setLoading(false);
          });
        });
    }
  };

  const onVerifyBtnClick = () => {
    otpVerification();
  };

  useEffect(() => {
    dispatch(changeMobileNo(registerData.mobileNo));
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 2);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, minutes, navigate, seconds, registerData.mobileNo]);

  return (
    <div>
      <Navebar title='OTP' />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: "100px",
        }}
      >
        <div
          className='main'
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "40px",
            width: "100%",
          }}
        >
          <img id='mob' src={mobile} alt='mobile' />
          <Typography
            style={{
              color: Colors.globalColorThemeColor,
              fontWeight: "bold",
              marginTop: "10vh",
              textAlign: "center",
            }}
            fontSize={{
              lg: "30px",
              md: "30px",
              sm: "22px",
              xs: "15.5px",
            }}
          >
            {t("We have sent you a code to verify your number")}
          </Typography>
          <Typography
            style={{ fontWeight: "bold" }}
            fontSize={{ lg: "25px", md: "25px", sm: "18px", xs: "15px" }}
          >
            {registerData.mobileNo ? `${registerData.mobileNo}` : mob_no}
          </Typography>
          <Typography
            style={{
              color: Colors.globalColorThemeColor,
              fontWeight: "bold",
              marginTop: "8vh",
            }}
            fontSize={{ lg: "29px", md: "29px", sm: "18px", xs: "15px" }}
          >
            {t("Enter the OTP here")}
          </Typography>
          <TextField
            autoFocus
            type='number'
            id='otpfield'
            variant='standard'
            // onKeyPress={(e) => e.key === "Enter" && handleverify()}
            onChange={(e) => setOtp(e.target.value)}
            onInput={(e) => (e.target.value = e.target.value.slice(0, 6))}
            InputProps={{ disableUnderline: true }}
          ></TextField>
          <Button
            disabled={loading}
            size='large'
            variant='contained'
            onClick={onVerifyBtnClick}
            style={{
              marginTop: "2vh",
              backgroundColor: loading ? "#E0E0E0" : Colors.globalColorThemeColor,
              fontWeight: "bolder",
              width: "270px",
            }}
          >
            {loading ? t("Verifying....") : t("Verify")}
          </Button>
          <div
            style={{
              width: "50vh",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography fontSize={{ lg: "15px", md: "15px", sm: "12px", xs: "12px" }}>
              {seconds > 0 || minutes > 0 ? (
                <p>
                  {t("Didn't recieve code?")}
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </span>
                </p>
              ) : (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <p>{t("Didn't recieve code?")}</p>
                  <Button
                    disabled={seconds > 0 || minutes > 0}
                    style={{
                      color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#1D1CE5",
                    }}
                    onClick={resendOTP}
                  >
                    <Typography
                      style={{ textTransform: "none", color: Colors.globalColorThemeColor }}
                      fontSize={{
                        lg: "15px",
                        md: "15px",
                        sm: "12px",
                        xs: "10px",
                      }}
                    >
                      {t("Resend OTP")}
                    </Typography>
                  </Button>
                </div>
              )}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPPage;
