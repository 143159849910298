import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography, Pagination } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import PreUploadedClassList from "./components/PreUploadedClassList";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import { APIClient } from "../../utils/APIClient";
import { useTranslation } from "react-i18next";

const PreUploadClasses = () => {
  const { t } = useTranslation(["translationPreUploadClasses"]);
  const navigate = useNavigate();
  const location = useLocation();
  const subjectColor = location?.state?.subjectColor;
  const subjectname = location?.state?.subject || "";

  const [preUploadClasses, setPreUploadClasses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [numbersOfPages, setNumbersOfPages] = useState(0);
  const recordsPerPage = 5;
  const changeCPage = (page) => {
    setCurrentPage(page);
  };
  const getAllPreuploadClasses = () => {
    const token = localStorage.getItem("token");
    const payload = {
      page: currentPage,
      itemCount: recordsPerPage,
      subject: subjectname,
    };
    axios
      .post(`${APIClient().API_BASE_URL}/api/getAllPreuploadClass`, payload, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setNumbersOfPages(response.data.noOfPages);
        setCurrentPage(currentPage);
        setPreUploadClasses(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getAllPreuploadClasses();
  }, [currentPage, subjectname]);

  return (
    <div>
      <SubjectNavbar />
      <Box sx={{ padding: "20px 30px" }}>
        <Box
          sx={{
            backgroundColor: subjectColor,
            borderRadius: "10px 10px 50px 10px",
            display: "flex",
            gap: "10px",
            padding: "10px",
          }}
        >
          <IconButton onClick={() => navigate(-1)} sx={{ p: 0, m: 0 }}>
            <ArrowBack sx={{ color: "#fff" }} />
          </IconButton>

          <Typography sx={{ fontSize: "18px", color: "#fff", fontWeight: 600 }}>
            {t("Pre Uploaded Classes")}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {preUploadClasses.map((item, index) => (
            <PreUploadedClassList key={index} data={item} />
          ))}
        </Box>
        <Box justifyContent='center' alignItems='center' display='flex'>
          <Pagination
            count={numbersOfPages}
            page={currentPage}
            onChange={(e, page) => changeCPage(page)}
            variant='outlined'
            shape='rounded'
          />
        </Box>
      </Box>
    </div>
  );
};

export default PreUploadClasses;
