import { Stack, Box, Typography, Button, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import SubjectNavbar from "../SubjectNavbar/SubjectNavbar";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AnimatedVideoClassIcon from "./images/animatedVideoClassIcon.svg";
import ChoiceBasedQuestionIcon from "./images/choiceBasedQuestionIcon.svg";
import ModelQuestionIcon from "./images/modelQuestionIcon.svg";
import ImportantConceptIcon from "./images/importantConceptIcon.svg";
import ClearDoubtIcon from "./images/clearDoubtIcon.svg";
import UpdatedSyllabusIcon from "./images/updatedSyllabusIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Colors from "../../utils/Colors";
import { tokenVerify } from "../../utils/Helpers";
import { useTranslation } from "react-i18next";

function AppFeatures() {
  const location = useLocation();
  const { t } = useTranslation(["translationAppFeatures"]);
  const state = location.state;
  const { loginData } = state ? state : false;
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const features = [
    {
      img: AnimatedVideoClassIcon,
      content: t("Learn With Animated Video Classes"),
    },
    {
      img: ChoiceBasedQuestionIcon,
      content: t("Boost your skills with choice based questions"),
    },
    {
      img: ModelQuestionIcon,
      content: t("make mock test practices with model question papers"),
    },
    {
      img: ImportantConceptIcon,
      content: t("upgrade your knowledge with important concepts"),
    },
    {
      img: ClearDoubtIcon,
      content: t("say hello anytime to clear your doubts"),
    },
    {
      img: UpdatedSyllabusIcon,
      content: t("updated syllabus content"),
    },
  ];
  const previousBtn = () => {
    setValue(value - 1);
  };

  const nextBtn = () => {
    setValue(value + 1);
  };

  const handleClickHomeFn = () => {
    navigate("/home", {
      state: {
        loginData: loginData,
      },
    });
  };

  document.onkeydown = checkKey;

  function checkKey(e) {
    e = e || window.event;
    if (value >= 0 && value <= 4) {
      if (e.keyCode === "39") {
        nextBtn();
      }
    }
    if (value >= 1 && value <= 5) {
      if (e.keyCode === "37") {
        previousBtn();
      }
    }
  }

  useEffect(() => {
    const isValidToken = tokenVerify();
    if (!state || !isValidToken) {
      navigate("/appfeature");
      return;
    }
  }, []);

  return (
    <>
      <Box>
        <SubjectNavbar title={"App Features"} />
        <Stack alignItems={"center"} sx={{ mt: 10 }}>
          <Box sx={{ width: { xs: "95%", sm: "90%", lg: "75%", xl: "70%" } }}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
              <IconButton disabled={value === 0 ? true : false} onClick={previousBtn}>
                <NavigateBeforeIcon
                  sx={{
                    width: { xs: "30px", sm: "50px", md: "70px" },
                    height: { xs: "40px", sm: "50px", md: "70px" },
                  }}
                />
              </IconButton>
              <Box>
                <Stack alignItems={"center"}>
                  <Box
                    component={"img"}
                    src={features[value].img}
                    sx={{
                      width: { xs: "230px", sm: "350px", md: "450px" },
                      height: { xs: "220px", sm: "350px", md: "440px" },
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "16px",
                        sm: "20px",
                        md: "22px",
                        lg: "25px",
                        xl: "28px",
                      },
                      color: Colors.globalColorThemeColor,
                      textAlign: "center",
                    }}
                  >
                    {features[value].content.toUpperCase()}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={{ xs: 2, md: 1 }}
                  mt={5}
                >
                  {features.map((items, index) =>
                    index === value ? (
                      <CircleOutlinedIcon
                        key={index}
                        sx={{
                          color: Colors.globalColorThemeColor,
                          width: { xs: "18px", sm: "25px", md: "45px" },
                        }}
                      />
                    ) : (
                      <CircleOutlinedIcon
                        key={index}
                        sx={{ width: { xs: "18px", sm: "25px", md: "45px" } }}
                      />
                    ),
                  )}
                </Stack>
              </Box>
              <IconButton disabled={value === features.length - 1 ? true : false} onClick={nextBtn}>
                <NavigateNextIcon
                  sx={{
                    width: { xs: "30px", sm: "50px", md: "70px" },
                    height: { xs: "40px", sm: "50px", md: "70px" },
                  }}
                />
              </IconButton>
            </Stack>
            <Stack alignItems={"center"} mt={5}>
              <Button
                variant='contained'
                onClick={handleClickHomeFn}
                sx={{
                  bgcolor: Colors.globalColorThemeColor,
                  ":hover": { bgcolor: Colors.globalColorThemeColor },
                }}
              >
                {t("Go To Home Page")}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </>
  );
}

export default AppFeatures;
