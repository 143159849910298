// import { useTranslation } from "react-i18next";
// const { t } = useTranslation(["translationNeetSubscriptionPlans"]);

export const planDetails = [
  {
    planName: "basic",
    FeaturesCount: 2,
    isTrail: false,
    price: 0,
  },
  {
    planName: "premium",
    FeaturesCount: 2,
    isTrail: true,
    price: 0,
  },
  {
    planName: "standard",
    FeaturesCount: 2,
    isTrail: false,
    price: 0,
  },
  {
    planName: "premium",
    FeaturesCount: 2,
    isTrail: false,
    price: 0,
  },
];

export const planDescription = [
  "Previous year solved questions and quizzes",
  "Mock exams",
  "Live Classes",
  "Pre-Upload Classes",
];
