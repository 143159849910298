import { createSlice } from "@reduxjs/toolkit";

const unitTopicsSlice = createSlice({
  name: "unitTopics",
  initialState: {
    unitTopics: [],
    allClassBookmarksData: {},
    bookmarkUnitTopics: {},
  },
  reducers: {
    setUnitTopics: (state, action) => {
      state.unitTopics = action.payload;
    },

    setAllClassBookmarksData: (state, action) => {
      state.allClassBookmarksData = action.payload;
    },

    setBookmarkUnitTopics: (state, action) => {
      state.bookmarkUnitTopics = action.payload;
    },
  },
});

export const { setUnitTopics, setBookmarkUnitTopics, setAllClassBookmarksData } =
  unitTopicsSlice.actions;

export const CurrentUnitTopics = (state) => state.unitTopics.unitTopics;

export const CurrentAllClassBookmarksData = (state) => state.unitTopics.allClassBookmarksData;

export const CurrentBookmarkUnitTopics = (state) => state.unitTopics.bookmarkUnitTopics;

export default unitTopicsSlice.reducer;
