import TamilCardEM from "./images/em/tamil.png";
import EnglishCardEM from "./images/em/english.png";
import MathsCardEM from "./images/em/maths.png";
import ChemistryCardEM from "./images/em/chemistry.png";
import BotanyCardEM from "./images/em/botany.png";
import ZoologyCardEM from "./images/em/zoology.png";
import CommerceCardEM from "./images/em/commerce.png";
import HistoryCardEM from "./images/em/history.png";
import GeographyCardEM from "./images/em/geography.png";
import StatisticsCardEM from "./images/em/statics.png";
import ComputerScienceCardEM from "./images/em/computer science.png";
import PhysicsCardEM from "./images/em/physics.png";
import AccountsCardEM from "./images/em/accountancy.png";
import EconomicsCardEM from "./images/em/economics.png";
import EvsCardEM from "./images/em/evs.png";
import ScienceCardEM from "./images/em/science.png";
import SocialCardEM from "./images/em/social.png";
import CivicsCardEM from "./images/em/civics.png";

import TamilCardTM from "./images/tm/tamil.png";
import EnglishCardTM from "./images/tm/english.png";
import MathsCardTM from "./images/tm/maths.png";
import MathematicsCardTM from "./images/tm/mathematics.png";
import ChemistryCardTM from "./images/tm/chemistry.png";
import BotanyCardTM from "./images/tm/botany.png";
import ZoologyCardTM from "./images/tm/zoology.png";
import CommerceCardTM from "./images/tm/commerce.png";
import HistoryCardTM from "./images/tm/history.png";
import GeographyCardTM from "./images/tm/geography.png";
import StatisticsCardTM from "./images/tm/statics.png";
import ComputerScienceCardTM from "./images/tm/computerscience.png";
import PhysicsCardTM from "./images/tm/physics.png";
import AccountsCardTM from "./images/tm/accountancy.png";
import EconomicsCardTM from "./images/tm/economics.png";
import EvsCardTM from "./images/tm/evs.png";
import ScienceCardTM from "./images/tm/science.png";
import SocialCardTM from "./images/tm/social.png";
import CivicsCardTM from "./images/tm/civics.png";
export const itemListEM = [
  {
    title: "Tamil",
    img: TamilCardEM,
    color: "#287F87",
  },
  {
    title: "English",
    img: EnglishCardEM,
    color: "#04019A",
  },
  {
    title: "Maths",
    img: MathsCardEM,
    color: "#39A2DB",
  },
  {
    title: "Environmental Science",
    img: EvsCardEM,
    color: "#31BC00",
  },
  {
    title: "Science",
    img: ScienceCardEM,
    color: "#FFB341",
  },
  {
    title: "Social Science",
    img: SocialCardEM,
    color: "#CD7F32",
  },
  {
    title: "History",
    img: HistoryCardEM,
    color: "#E0701A",
  },
  {
    title: "Geography",
    img: GeographyCardEM,
    color: "#39A6A3",
  },
  {
    title: "Civics",
    img: CivicsCardEM,
    color: "#2C2C2E",
  },
  {
    title: "Economics",
    img: EconomicsCardEM,
    color: "#990134",
  },
  {
    title: "Physics",
    img: PhysicsCardEM,
    color: "#D83955",
  },
  {
    title: "Chemistry",
    img: ChemistryCardEM,
    color: "#00CC99",
  },
  {
    title: "Botany",
    img: BotanyCardEM,
    color: "#5D8234",
  },
  {
    title: "Zoology",
    img: ZoologyCardEM,
    color: "#753422",
  },
  {
    title: "Accountancy",
    img: AccountsCardEM,
    color: "#CE2C21",
  },
  {
    title: "Commerce",
    img: CommerceCardEM,
    color: "#9B72AA",
  },
  {
    title: "Statistics",
    img: StatisticsCardEM,
    color: "#666698",
  },
  {
    title: "Computer Science",
    img: ComputerScienceCardEM,
    color: "#726F82",
  },
];

export const itemListTM = [
  {
    title: "தமிழ்",
    img: TamilCardTM,
    color: "#287F87",
  },
  {
    title: "English",
    img: EnglishCardTM,
    color: "#04019A",
  },
  {
    title: "கணிதம்",
    img: MathsCardTM,
    color: "#39A2DB",
  },
  {
    title: "கணிதவியல்",
    img: MathematicsCardTM,
    color: "#287F87",
  },
  {
    title: "சூழ்நிலையியல்‌",
    img: EvsCardTM,
    color: "#31BC00",
  },
  {
    title: "அறிவியல்",
    img: ScienceCardTM,
    color: "#FFB341",
  },
  {
    title: "சமூக அறிவியல்",
    img: SocialCardTM,
    color: "#CD7F32",
  },
  {
    title: "வரலாறு",
    img: HistoryCardTM,
    color: "#E0701A",
  },
  {
    title: "புவியியல்",
    img: GeographyCardTM,
    color: "#39A6A3",
  },
  {
    title: "குடிமையியல்",
    img: CivicsCardTM,
    color: "#2C2C2E",
  },
  {
    title: "பொருளியல்",
    img: EconomicsCardTM,
    color: "#990134",
  },
  {
    title: "இயற்பியல்",
    img: PhysicsCardTM,
    color: "#D83955",
  },
  {
    title: "வேதியியல்",
    img: ChemistryCardTM,
    color: "#00CC99",
  },
  {
    title: "தாவரவியல்",
    img: BotanyCardTM,
    color: "#5D8234",
  },
  {
    title: "விலங்கியல்",
    img: ZoologyCardTM,
    color: "#753422",
  },
  {
    title: "கணக்கு பதிவியல்",
    img: AccountsCardTM,
    color: "#CE2C21",
  },
  {
    title: "வணிகவியல்",
    img: CommerceCardTM,
    color: "#9B72AA",
  },
  {
    title: "புள்ளியியல்",
    img: StatisticsCardTM,
    color: "#666698",
  },
  {
    title: "கணினி அறிவியல்",
    img: ComputerScienceCardTM,
    color: "#726F82",
  },
];
