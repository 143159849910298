import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const PercentageCircle = ({
  percentage,
  filledColor,
  borderColor,
  backgroundColor,
  insideColor,
}) => {
  const colorSize = percentage * 0.01;

  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress
        variant='determinate'
        value={100}
        size={100}
        style={{ color: backgroundColor }}
      />
      <CircularProgress
        variant='determinate'
        value={colorSize * 100}
        size={100}
        style={{ color: filledColor, position: "absolute", top: 0, left: 0 }}
      />
    </Box>
  );
};

export default PercentageCircle;
