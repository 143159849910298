import LoginIcon from "@mui/icons-material/Login";
import React, { useState } from "react";
import { AppBar, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import "./LandingPageNavStyle.css";
import home from "./Images/home.png";
import iconfeature from "./Images/iconfeatur.png";
import iconvideo from "./Images/iconvideoe.png";
import contact from "./Images/contacticon.png";
import logoimg from "./Images/gg.svg";
import LanguageIcon from "@mui/icons-material/Language";
function LandingPageNavbar({
  onButton1Click,
  onButton2Click,
  onButton3Click,
  onButton4Click,
  onButton5Click,
}) {
  const [clicked, setClicked] = useState(false);
  const { t } = useTranslation();

  const handleDrawerOpen = () => {
    setClicked(!clicked);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };

  return (
    <div>
      <AppBar>
        <nav className='tcsnavbar'>
          <img src={logoimg} className='tcsimgsize' alt='logo' />
          <ul className={clicked ? "tcsunorderlist active" : "tcsunorderlist"}>
            <li className='tcslist' onClick={onButton1Click}>
              <img src={home} alt={"home"} className='tcshomeicons' /> {t("home")}
            </li>
            <li className='tcslist' onClick={onButton2Click}>
              <img src={iconfeature} alt={"feature"} className='tcsfeatureicons' /> {t("feature")}
            </li>
            <li className='tcslist' onClick={onButton3Click}>
              <img src={iconvideo} alt={"app demo"} className='tcsappdemoicons' /> {t("appDemo")}
            </li>
            <li className='tcslist' onClick={onButton4Click}>
              <img src={contact} className='tcscontacticons' alt={"contact us"} />
              {t("contactUs")}
            </li>
            <li className='tcslist' onClick={onButton5Click}>
              <LoginIcon className='tcsgetstart' /> {t("getStarted")}
            </li>
            <li className='translate-container'>
              <LanguageIcon className='language-icon' />
              <Select
                value={i18n.language}
                onChange={(e) => changeLanguage(e.target.value)}
                className='tcsdropdown'
                disableUnderline={true}
                renderValue={(value) => (value === "en" ? "EN" : "TA")}
              >
                <MenuItem value='en'>English</MenuItem>
                <MenuItem value='ta'>தமிழ்</MenuItem>
              </Select>
            </li>
          </ul>
          <div className='tcsmobile'>
            <i className={clicked ? "fas fa-times" : "fas fa-bars"} onClick={handleDrawerOpen}></i>
          </div>
        </nav>
      </AppBar>
    </div>
  );
}

export default LandingPageNavbar;
