import React from "react";
import ReactPlayer from "react-player";

const VideoPlayer = ({ src }) => {
  return (
    <ReactPlayer
      className='react-player'
      url={src}
      width='100%'
      height='400px'
      controls={true}
      config={{ file: { attributes: { controlsList: "nodownload" } } }} // Disable download button
      seeking={true}
    />
  );
};

export default VideoPlayer;
