import { forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import { Button as MuiButton, CircularProgress, Box, styled, useTheme } from "@mui/material";

const StyledButton = styled(MuiButton)(({ theme, borderGray, whiteBg, color, variant }) => ({
  position: "relative",
  backgroundColor: whiteBg && variant === "outlined" ? "#fff" : undefined,
  ...(borderGray && variant === "outlined"
    ? {
        border: "1px solid rgba(105, 119, 140, 0.5)",
        "&:hover": {
          border: "1px solid var(--text-primary-color)",
          backgroundColor: "var(--sk-icy-white)",
        },
      }
    : {}),
}));

const Button = forwardRef((props, ref) => {
  const {
    loading,
    disabled,
    children,
    borderGray,
    whiteBg,
    custom,
    color,
    StartIcon,
    EndIcon,
    onClick,
    customStyles,
    fullWidth,
    ...rest
  } = props;

  const theme = useTheme();
  const inputref = useRef(null);

  return (
    <StyledButton
      {...rest}
      disabled={disabled || loading}
      borderGray={borderGray}
      whiteBg={whiteBg}
      color={color}
      startIcon={StartIcon ? <StartIcon /> : undefined}
      endIcon={EndIcon ? <EndIcon /> : undefined}
      ref={ref}
      style={{ width: fullWidth ? "100%" : "" }}
      sx={customStyles}
      onClick={onClick}
    >
      {loading && (
        <Box
          position='absolute'
          width='100%'
          height='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <CircularProgress size={24} sx={{ color: "grey" }} />
        </Box>
      )}
      {!loading ? children : "."}
    </StyledButton>
  );
});

Button.defaultProps = {
  onClick: () => {},
  variant: "contained",
};

/**
 * Props type and default props for Button
 */

Button.propTypes = {
  loading: PropTypes.bool,
  custom: PropTypes.bool,
  borderGray: PropTypes.bool,
  disabled: PropTypes.bool,
  whiteBg: PropTypes.bool,
  StartIcon: PropTypes.any,
  EndIcon: PropTypes.any,
  fullWidth: PropTypes.bool,
  color: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "success",
    "error",
    "info",
    "warning",
  ]),
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["contained", "outlined", "text", "filledTonal", "gradient"]),
  onClick: PropTypes.func,
  customStyles: PropTypes.object,
};

export default Button;
