import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Alert, Button, Card, Snackbar, Typography } from "@mui/material";
import "./Choosetheclassstyle.css";
import Navebar from "../../Navbar/Navebar";
import { useSelector, useDispatch } from "react-redux";
import {
  changeBoard,
  changeMedium,
  changeClass,
  CurrentClass,
  CurrentBoard,
  CurrentMedium,
} from "../../redux/registerDataSlice";
import { APIClient } from "../../utils/APIClient";
import Colors from "../../utils/Colors";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";

function Choosetheclass() {
  const { t } = useTranslation(["translationChoosetheclass"]);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { loginData, getBoard, getMedium, ChangeToStateBoard, ChangeToNeet } = state;
  const token = localStorage.getItem("token");
  const getClass = useSelector(CurrentClass);
  const Medium = useSelector(CurrentMedium);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const standared = [
    { class: "1st", value: 1 },
    { class: "2nd", value: 2 },
    { class: "3rd", value: 3 },
    { class: "4th", value: 4 },
    { class: "5th", value: 5 },
    { class: "6th", value: 6 },
    { class: "7th", value: 7 },
    { class: "8th", value: 8 },
    { class: "9th", value: 9 },
    { class: "10th", value: 10 },
    { class: "11th", value: 11 },
    { class: "12th", value: 12 },
  ];

  const nextBtn = () => {
    if (getClass === "") {
      enqueueSnackbar("Please Choose the Class", { variant: "error" });
      setChecked(true);
    } else if (token) {
      const payload = {
        board: getBoard === "cb" ? "cb" : "sb",
        className: getClass,
        medium: Medium,
      };
      axios
        .put(`${APIClient().API_BASE_URL}/api/updateBoardAndClass`, payload, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            navigate("/home");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      navigate("/register", { state: { getBoard, getMedium, getClass } });
    }
  };

  useEffect(() => {
    dispatch(changeBoard(getBoard));
    dispatch(changeMedium(getMedium));
  }, []);

  return (
    <>
      <div>
        <Navebar title={t("Select Class")} />
        <div className='twodivinside'>
          <div className='cardes'>
            <div className='seconddiv'>
              <Typography
                className='chooseclass'
                sx={{
                  fontSize: { xs: "15px", sm: "25px", md: "28px", lg: "28px", xl: "28px" },
                }}
                variant='h8'
                component={"h2"}
              >
                {t("Choose Your Class")}
              </Typography>
              <div className='choosecards'>
                {standared.map((items, index) => (
                  <Card
                    key={index}
                    className='mapcards'
                    elevation={5}
                    onClick={() => dispatch(changeClass(items.value))}
                    sx={{
                      cursor: "pointer",
                      bgcolor: items.value === getClass ? "#DFF3FE" : "white",
                      border:
                        items.value === getClass
                          ? "2px solid " + Colors.globalColorThemeColor
                          : "black",
                    }}
                  >
                    {items.class}
                  </Card>
                ))}
              </div>
            </div>
          </div>
          <div className='footbtn'>
            <Button
              variant='contained'
              className='choosebtn'
              onClick={nextBtn}
              sx={{
                backgroundColor: Colors.globalColorThemeColor,
                "&:hover": {
                  backgroundColor: Colors.globalColorThemeColor,
                },
              }}
            >
              {t("Next")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Choosetheclass;
