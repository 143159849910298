import React from "react";
import PlayCircleOutlineOutlinedIcon from "@mui/icons-material/PlayCircleOutlineOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import { Box, Card, Typography } from "@mui/material";
import UnitsQandA from "../UnitsQandA";
import UnitsQuiz from "../UnitsQuiz";
import UnitVideos from "../UnitVideos";
import { useTranslation } from "react-i18next";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import HeaderBox from "../../../../common/components/HeaderBox";
import Colors from "../../../../utils/Colors";
const UnitDetails = ({
  tabSelected,
  subjectColor,
  buttonClicked,
  handleTabBtnClick,
  handleBackBtnClick,
  color,
  loginData,
  subjectname,
  unitName,
  unitNo,
  videoList,
  mcqList,
  qaList,
  unitList,
}) => {
  const { t } = useTranslation(["translationUnits"]);
  return (
    <div style={{ width: "100%" }}>
      <div style={{ padding: "10px 50px" }}>
        <HeaderBox
          bgcolor={subjectColor}
          title={`${unitNo} - ${unitName}`}
          backBtn={true}
          onBackBtn={handleBackBtnClick}
        />
        <div className='videoMesgQuizDiv'>
          <div className='unitsSubHeads'>
            <div className='unitsSecSubHeads' style={{ border: `1px solid ${subjectColor}` }}>
              <Typography
                className='unitVideos'
                sx={{
                  padding: "5px",
                  gap: "5px",
                  borderRadius: "255px",
                  bgcolor: tabSelected === "video" ? subjectColor : "transparent",
                }}
                onClick={() => handleTabBtnClick("video")}
              >
                <PlayCircleOutlineOutlinedIcon
                  className='circleOutLineVideosIcon'
                  sx={{ color: tabSelected === "video" ? "white" : subjectColor }}
                />
                <span
                  className='circleOutLineVideosIcon'
                  style={{
                    color: tabSelected === "video" ? "white" : subjectColor,
                  }}
                >
                  {t("Videos")}
                </span>
              </Typography>

              <Typography
                className='unitVideos'
                sx={{
                  padding: "5px",
                  gap: "5px",
                  borderRadius: "255px",
                  bgcolor: tabSelected === "qa" ? subjectColor : "transparent",
                }}
                onClick={() => handleTabBtnClick("qa")}
              >
                <ForumOutlinedIcon
                  className='circleOutLineVideosIcon'
                  sx={{ color: tabSelected === "qa" ? "white" : subjectColor }}
                />
                <span
                  className='circleOutLineVideosIcon'
                  style={{ color: tabSelected === "qa" ? "white" : subjectColor }}
                >
                  {t("Q & A")}
                </span>
              </Typography>

              <Typography
                className='unitVideos'
                sx={{
                  padding: "5px",
                  gap: "5px",
                  borderRadius: "255px",
                  bgcolor: tabSelected === "quiz" ? subjectColor : "transparent",
                }}
                onClick={() => handleTabBtnClick("quiz")}
              >
                <QuizOutlinedIcon
                  className='circleOutLineVideosIcon'
                  sx={{ color: tabSelected === "quiz" ? "white" : subjectColor }}
                />
                <span
                  className='circleOutLineVideosIcon'
                  style={{
                    color: tabSelected === "quiz" ? "white" : subjectColor,
                  }}
                >
                  {t("Quiz")}
                </span>
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className='bb'>
        {buttonClicked === "video" && (
          <UnitVideos
            subjectColor={subjectColor}
            unitSelectedIndex={color}
            loginData={loginData}
            subjectname={subjectname}
            videoList={videoList}
            unitList={unitList}
          />
        )}
        {buttonClicked === "qa" && <UnitsQandA qaList={qaList} />}
        <div className='sss'>{buttonClicked === "quiz" && <UnitsQuiz mcqList={mcqList} />}</div>
      </div>
    </div>
  );
};

export default UnitDetails;
