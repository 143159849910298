import { createSlice } from "@reduxjs/toolkit";

const unitMCQsSlice = createSlice({
  name: "unitMCQs",
  initialState: {
    unitMCQs: [],
    unitsBalanceMCQs: [],
  },
  reducers: {
    setUnitMCQs: (state, action) => {
      state.unitMCQs = action.payload;
    },
    setUnitsBalanceMCQs: (state, action) => {
      state.unitsBalanceMCQs = action.payload;
    },
  },
});

export const { setUnitMCQs, setUnitsBalanceMCQs } = unitMCQsSlice.actions;

export const currentUnitMCQs = (state) => state.unitMCQs.unitMCQs;

export const CurrentBalanceMCQs = (state) => state.unitMCQs.unitsBalanceMCQs;

export default unitMCQsSlice.reducer;
