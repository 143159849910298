import { createSlice } from "@reduxjs/toolkit";

const unitsQAsSlice = createSlice({
  name: "unitQAs",
  initialState: {
    unitQAsData: [],
    qas: [],
  },
  reducers: {
    setUnitQAs: (state, action) => {
      state.unitQAsData = action.payload;
    },
    setQAs: (state, action) => {
      state.qas = action.payload;
    },
  },
});

export const { setUnitQAs, setQAs } = unitsQAsSlice.actions;

export const CurrentUnitQAs = (state) => state.unitQAs.unitQAsData;

export const CurrentQAs = (state) => state.unitQAs.qas;

export default unitsQAsSlice.reducer;
