import { createSlice } from "@reduxjs/toolkit";

export const stateplanNamePlanDataSlice = createSlice({
  name: "stateplanNamePlanData",
  initialState: {
    planName: "",
    amount: "",
    paymentFrequency: "",
  },
  reducers: {
    changeplanName: (state, action) => {
      state.planName = action.payload;
    },
    changeamount: (state, action) => {
      state.amount = action.payload;
    },
    changepaymentFrequency: (state, action) => {
      state.paymentFrequency = action.payload;
    },
  },
});

export const { changeplanName, changeamount, changepaymentFrequency } =
  stateplanNamePlanDataSlice.actions;

export const CurrentplanName = (state) => state.stateplanNamePlanData.planName;
export const Currentamount = (state) => state.stateplanNamePlanData.amount;
export const CurrentpaymentFrequency = (state) => state.stateplanNamePlanData.paymentFrequency;
export const CurrentValueForStateplanNamePlanData = (state) => state.stateplanNamePlanData;

export default stateplanNamePlanDataSlice.reducer;
