import React, { useState, useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  CardMedia,
  Box,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import Colors from "../../../utils/Colors";
import Tooltip from "@mui/material/Tooltip";
import VideoPlayer from "../components/VideoPlayer";
import { Close } from "@mui/icons-material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { useLocation, useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
const PreUploadedClassList = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const subjectColor = location?.state?.subjectColor;
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handlePlay = () => {
    setOpenDialog(true);
  };
  const unlock = () => {
    navigate("/neetsubscriptionplans");
  };
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        marginTop: "10px",
        height: "180px",
        width: "100%",
        marginBottom: "10px",
        border: "1px solid #ccc",
        boxSizing: "border-box",
        borderRadius: "5px",
      }}
    >
      {/* <CardMedia
              component='img'
              sx={{
                width: { xs: "100%", sm: "120px" },
                height: { xs: "auto", sm: "auto" },
                objectFit: "cover",
                "@media (max-width: 600px)": {},
              }}
              image={items.videoImg}
              alt='placeholder'
            /> */}
      <Box
        className='preuploadimgtagbox'
        // sx={{
        //   backgroundColor: { xs: "blue", sm: "viloet", md: "red", lg: "red", xl: "red" },
        //   height: "100%",
        //   width: { xs: "20%", sm: "20%", md: "14%", lg: "14%", xl: "14%" },
        // }}
      >
        <img
          src={data.videoImg}
          // style={{ width: "100%", height: "40%" }}
          className='preuploadimgtag'
        />
      </Box>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          // flexDirection: "column",
          flexWrap: "wrap",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              opacity: 0.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                fontWeight: "bold",
              }}
            >
              <Typography
                sx={{
                  marginRight: "10px",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                }}
              >
                Title
              </Typography>
              <Typography
                sx={{
                  minWidth: "20vw",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                }}
                variant='body3'
              >
                {data.title}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                fontWeight: "bold",
                marginLeft: {
                  xs: "10px",
                  sm: "-8px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
              }}
            >
              <Typography
                sx={{
                  marginRight: "10px",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                }}
              >
                Subject
              </Typography>
              <Typography
                sx={{
                  minWidth: "10vw",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                }}
                variant='body3'
              >
                {data.subject}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              fontWeight: "bold",
            }}
          >
            <Typography
              sx={{
                marginRight: "10px",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              Description
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <Typography
                      sx={{
                        maxWidth: "65vw",
                        lineHeight: "1.2",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        opacity: 0.5,
                        wordBreak: "break-word",
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                      variant='body3'
                    >
                      {items.desc.length > 100 ? `${items.desc.slice(0, 100)}...` : items.desc}
                    </Typography>
                    {items.desc.length > 100 && (
                      <Tooltip title={items.desc} placement='bottom-end'>
                        <Typography
                          gutterBottom
                          color={Colors.globalColorThemeColor}
                          sx={{
                            lineHeight: "1.2",
                            marginBottom: 0,
                            cursor: "pointer",
                            alignSelf: "flex-start",
                            width: "100px",
                            textAlign: "right",
                            textWrap: "nowrap",
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "15px",
                              lg: "15px",
                              xl: "15px",
                            },
                          }}
                        >
                          {t("Read more")}
                        </Typography>
                      </Tooltip>
                    )} */}
              <Typography
                sx={{
                  maxWidth: "65vw",
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                  lineHeight: "1.2",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  opacity: 0.5,
                  wordBreak: "break-word", // Ensures long words break and wrap within the container
                  whiteSpace: "normal", // Allows the text to wrap normally
                }}
              >
                {/* Display first 50 characters of desc */}
                {data.desc.length > 50 ? `${data.desc.slice(0, 50)}...` : data.desc}

                {/* Tooltip button to read more */}
                {data.desc.length > 50 && (
                  <Tooltip
                    title={data.desc}
                    placement='bottom-end'
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    {/* "Read more" button styled as inline text */}
                    <Typography
                      component='span'
                      color={Colors.globalColorThemeColor}
                      sx={{
                        cursor: "pointer",
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      &nbsp;Read more
                    </Typography>
                  </Tooltip>
                )}
              </Typography>
            </Box>
            {/* <Typography
                    sx={{
                      marginRight: "10px",
                      fontSize: {
                        // xs: "blue",
                        sm: "12px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                    }}
                  >
                    Description
                  </Typography>
                  <Typography
                    sx={{
                      maxWidth: "65vw",
                      lineHeight: "1.2",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      textOverflow: "ellipsis",
                      opacity: 0.5,
                      wordBreak: "break-word",
                      fontSize: {
                        // xs: "blue",
                        sm: "12px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                    }}
                    variant='body3'
                  >
                    {items.desc}
                  </Typography> */}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            opacity: 0.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              fontWeight: "bold",
            }}
          >
            <Typography
              sx={{
                marginRight: "10px",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              Author
            </Typography>
            <Typography
              sx={{
                minWidth: "20vw",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
              variant='body3'
            >
              {data.author}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              fontWeight: "bold",
              marginLeft: {
                xs: "10px",
                sm: "-8px",
                md: "20px",
                lg: "20px",
                xl: "20px",
              },
            }}
          >
            <Typography
              sx={{
                marginRight: "10px",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              Date
            </Typography>
            <Typography
              sx={{
                minWidth: "10vw",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "15px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
              variant='body3'
            >
              {new Date(data.date).toLocaleDateString()}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      {data.isLocked === false ? (
        <CardActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "10px",
            marginLeft: "auto",
            // "@media (max-width: 1024px)": {
            //   justifyContent: "center",
            // },
          }}
        >
          <Button
            variant='contained'
            size='small'
            onClick={() => handlePlay(data.videoLink)}
            fullWidth
            sx={{
              // width: "90%",
              // marginLeft: "8px",
              backgroundColor: subjectColor,
              // padding: "4px 30px",
              padding: {
                xs: "4px 15px",
                sm: "4px 30px",
                md: "4px 30px",
                lg: "4px 30px",
                xl: "4px 30px",
              },

              // fontSize: "13px",
              fontSize: {
                xs: "10px",
                sm: "13px",
                md: "13px",
                lg: "13px",
                xl: "13px",
              },
            }}
          >
            <PlayCircleIcon sx={{ margin: "auto", fontSize: "4.5rem" }} />
          </Button>
        </CardActions>
      ) : (
        <CardActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginLeft: "auto",
          }}
        >
          <Button
            variant='contained'
            size='small'
            onClick={unlock}
            fullWidth
            sx={{
              backgroundColor: "#DAA520",
              fontSize: {
                xs: "10px",
                sm: "13px",
                md: "13px",
                lg: "13px",
                xl: "13px",
              },
              // padding: "4px 10px",
              padding: {
                xs: "4px 4px",
                sm: "4px 10px",
                md: "4px 10px",
                lg: "4px 10px",
                xl: "4px 10px",
              },
            }}
            startIcon={<LockIcon />}
          >
            unlock
          </Button>
        </CardActions>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth='md'>
        <DialogContent>
          <IconButton
            aria-label='close'
            onClick={handleCloseDialog}
            sx={{
              top: 0,
              right: 2,
              position: "absolute",
              color: "primary.dark",
            }}
          >
            <Close />
          </IconButton>
          <Typography color={subjectColor} sx={{ mb: "5px" }} variant='body1'>
            {data.title}
          </Typography>
          <VideoPlayer src={data.videoLink} />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default PreUploadedClassList;
