import { Box, Typography, Button, Container } from "@mui/material";
import { styled } from "@mui/system";
import backgroundImage from "../../assets/gif1404.gif";

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: "6rem",
  fontWeight: 700,
  marginBottom: theme.spacing(2),

  [theme.breakpoints.down("md")]: {
    fontSize: "4rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "3rem",
  },
}));

const MessageTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.25rem",
  fontWeight: 400,
  marginBottom: theme.spacing(4),

  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.875rem",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: "1rem",
  padding: theme.spacing(1, 4),
  borderRadius: "8px",

  [theme.breakpoints.down("md")]: {
    fontSize: "0.875rem",
    padding: theme.spacing(1, 3),
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
    padding: theme.spacing(1, 2),
  },
}));
const BackgroundBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
});
export { StyledButton, MessageTypography, TitleTypography, BackgroundBox };
