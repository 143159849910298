import React, { useState } from "react";
import { AppBar, Typography, TextField } from "@mui/material";
import "./GillgallDeletion.css";
import axios from "axios";
import Swal from "sweetalert2";
import Colors from "../../utils/Colors";
import { APIClient } from "../../utils/APIClient";
import Button from "../../common/components/DesignSystem/Button/Button";
const GillgallDeletion = () => {
  const [mobNumber, setMobNumber] = useState("");
  const [errormsg, setErrorMsg] = useState("");
  const [isvalid, setIsValid] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleChangedeleteMobNo = (e) => {
    const number = e.target.value;

    setMobNumber(number);
    setErrorMsg("");
    setIsValid(false);

    if (number.length === 10 && /^[0-9]{10}$/.test(number)) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const handleDelbtnClick = () => {
    if (mobNumber === "") {
      setErrorMsg("Please Enter Number");
      setIsValid(true);
    } else {
      setLoading(true);
      const sendTheMobileNumber = {
        mob_no: "+91" + mobNumber.replace(/\s+/g, "").replace("-", ""),
      };

      axios
        .post(`${APIClient().API_BASE_URL}/api/deleteAccount`, sendTheMobileNumber)
        .then((response) => {
          Swal.fire({
            icon: "success",
            confirmButtonColor: Colors.globalColorThemeColor,
            title: response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          }).then(() => {
            setMobNumber("");
            setIsButtonDisabled(true);
            setLoading(false);
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            confirmButtonColor: Colors.globalColorThemeColor,
            title: error.response.data.message,
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
          });
        });
    }
  };

  return (
    <>
      <div className='container'>
        <AppBar>
          <Typography variant='h3' className='headText'>
            Gillgall
          </Typography>
        </AppBar>
        <div className='text-box'>
          <div className='para-field'>
            <Typography className='para-text'>
              Enter your Mobile Number to delete account in Gillgall
            </Typography>
          </div>
          <div className='entermobilenumber'>
            <TextField label='+91' disabled className='small' />
            <div className='input-container'>
              <input
                type='tel'
                autoFocus
                inputMode='numeric'
                id='mobileNo'
                pattern='[0-9]{10}'
                maxLength='10'
                onChange={handleChangedeleteMobNo}
                value={mobNumber}
                className='big'
                placeholder='Enter your Mobile Number'
                style={{
                  letterSpacing: "0.1em",
                  paddingLeft: "15px",
                  borderWidth: "2px",
                }}
              />
              <small className='helper-text' id='helperText'></small>
            </div>
          </div>
          <div className='btn-field'>
            <Button
              variant='contained'
              onClick={handleDelbtnClick}
              disabled={loading || isButtonDisabled}
              loading={loading}
              customStyles={{ width: "395px", marginTop: "10px" }}
            >
              Delete Account
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GillgallDeletion;
