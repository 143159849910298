import React, { useEffect } from "react";

const AdsComponent = ({ dataAdSlot }) => {
  useEffect(() => {
    try {
      // Check if the adsbygoogle object is already defined and if the container is empty
      if (
        typeof window.adsbygoogle !== "undefined" &&
        window.adsbygoogle.push &&
        document.querySelectorAll(".adsbygoogle").length === 0
      ) {
        window.adsbygoogle.push({});
      }
    } catch (error) {
      console.error("Error loading Google AdSense:", error);
    }
  }, []);

  return (
    <ins
      className='adsbygoogle'
      style={{ display: "block" }}
      data-ad-client='ca-pub-XXXXXXXXXXXXXXX'
      data-ad-slot={dataAdSlot}
      data-ad-format='auto'
      data-full-width-responsive='true'
    ></ins>
  );
};

export default AdsComponent;
