import { createSlice } from "@reduxjs/toolkit";

export const registerDataSlice = createSlice({
  name: "registerData",
  initialState: {
    board: "",
    medium: "",
    class: "",
    name: "",
    mobileNo: "",
    emailID: "",
  },
  reducers: {
    changeBoard: (state, action) => {
      state.board = action.payload;
    },
    changeMedium: (state, action) => {
      state.medium = action.payload;
    },
    changeClass: (state, action) => {
      state.class = action.payload;
    },
    changeName: (state, action) => {
      state.name = action.payload;
    },
    changeMobileNo: (state, action) => {
      state.mobileNo = action.payload;
    },
    changeEmailId: (state, action) => {
      state.emailID = action.payload;
    },
  },
});

export const { changeBoard, changeMedium, changeClass, changeName, changeMobileNo, changeEmailId } =
  registerDataSlice.actions;

export const CurrentBoard = (state) => state.registerData.board;
export const CurrentMedium = (state) => state.registerData.medium;
export const CurrentClass = (state) => state.registerData.class;
export const CurrentName = (state) => state.registerData.name;
export const CurrentMobileNo = (state) => state.registerData.mobileNo;
export const CurrentEmailId = (state) => state.registerData.emailID;
export const CurrentValue = (state) => state.registerData;

export default registerDataSlice.reducer;
