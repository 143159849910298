import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { CurrentUnitQAs, CurrentQAs } from "../../../redux/unitQAsSlice";
import { useSelector } from "react-redux";
import emptyques from "../../Units/Image/basket-empty.png";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import "./UnitsQandA.css";
import { useLocation } from "react-router-dom";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
function UnitsQandA({ qaList }) {
  const { t } = useTranslation(["translationUnitsQandA"]);
  const unitQas = useSelector(CurrentUnitQAs);
  const unitqasdata = useSelector(CurrentQAs);
  const [selected, setSelected] = useState(null);
  const location = useLocation();
  const subjectColor = location.state.subjectColor;
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  useEffect(() => {
    setSelected(null);
  }, [unitqasdata]);

  return (
    <div className='scrollable-container'>
      <div className='wrapperes'>
        {qaList.length !== 0 ? (
          qaList.map((item, index) => (
            <Box
              key={index}
              elevation={5}
              className='accordion'
              sx={{ border: `1px solid ${subjectColor}` }}
            >
              <div className='item'>
                <div className='title' onClick={() => toggle(index)}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>{index + 1}. </Typography>
                    <ReactQuill
                      value={item.question}
                      readOnly={true}
                      modules={{ toolbar: false }}
                      style={{ border: "none" }}
                    />
                  </Box>
                  <div className='answer'>
                    <span>
                      {selected === index ? (
                        <Typography sx={{ textWrap: "nowrap" }} className='hideAnswer'>
                          {" "}
                          {t("Hide Answer")}
                        </Typography>
                      ) : (
                        <Typography sx={{ textWrap: "nowrap" }} className='showAnswer'>
                          {t("Show Answer")}
                        </Typography>
                      )}
                    </span>
                  </div>
                </div>
                <ReactQuill
                  className={selected === index ? "content.show" : "content"}
                  value={item.answer}
                  readOnly={true}
                  modules={{ toolbar: false }}
                />
              </div>
            </Box>
          ))
        ) : (
          <div className='emptyques'>
            <img className='emptyquesimage' src={emptyques} alt={"icon"} />
            <Typography
              sx={{
                textWrap: "nowrap",
                fontWeight: "100px",
                fontSize: { xs: "11px", sm: "13px", md: "15px", lg: "17px", xl: "19px" },
              }}
            >
              {t("Definitions are not yet available. We Will provide them soon")}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}
export default UnitsQandA;
