import React from "react";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";

const colorConfig = {
  primary: "#35AFFD",
  secondary: "#FF8D2A",
};

export const theme = () => {
  return createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            userSelect: "none",
            fontFamily: `Poppins, sans-serif`,
            fontSize: 14,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontFamily: "Poppins, sans-serif",
            fontSize: 14,
          },
        },
      },
    },
    typography: {
      fontFamily: `Poppins, sans-serif`,
      fontSize: 14,
    },
    palette: {
      primary: {
        main: colorConfig.primary,
        contrastText: "#fff",
      },
      secondary: {
        main: colorConfig.secondary,
        contrastText: "#fff",
      },
    },
  });
};

const MuiTheme = (props) => {
  return (
    <ThemeProvider theme={theme()}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
};

export default MuiTheme;
