const Colors = {
  globalColorThemeColor: "#35affc",
  English: "#04019A",
  அறிவியல்: "#FFB341",
  இயற்பியல்: "#D83955",
  "கணக்கு பதிவியல்": "#CE2C21",
  கணிதம்: "#39A2DB",
  கணிதவியல்: "#287F87",
  "கணினி அறிவியல்": "#726F82",
  குடிமையியல்: "#2C2C2E",
  "சமூக அறிவியல்": "#CD7F32",
  சூழ்நிலையியல்‌: "#31BC00",
  தமிழ்: "#287F87",
  தாவரவியல்: "#5D8234",
  புள்ளியியல்: "#666698",
  புவியியல்: "#39A6A3",
  பொருளியல்: "#990134",
  வணிகவியல்: "#9B72AA",
  வரலாறு: "#E0701A",
  விலங்கியல்: "#753422",
  வேதியியல்: "#00CC99",

  Accountancy: "#CE2C21",
  Botany: "#5D8234",
  Chemistry: "#00CC99",
  Civics: "#2C2C2E",
  Commerce: "#9B72AA",
  "Computer Science": "#726F82",
  Economics: "#990134",
  "Environmental Science": "#31BC00",
  Geography: "#39A6A3",
  History: "#E0701A",
  Maths: "#39A2DB",
  Physics: "#D83955",
  Science: "#FFB341",
  "Social Science": "#CD7F32",
  Statistics: "#666698",
  Tamil: "#287F87",
  Zoology: "#753422",
};

export default Colors;
